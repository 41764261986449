import React from 'react';

export default (props)=>{

     return (
    <div className="sq_main_left">
        <div className="sq_tggg">
            <h2 className="sq_tgzx_title clear">
                <a className="sq_more" href="//www.gyyx.cn/news/list/Trends/1/">查看更多</a>
                光宇动态
            </h2>
            <ul className="sq_tggg_list newlist">
            </ul>
        </div>
        <div className="sq_tggg sq_margin_top">
            <h2 className="sq_tgzx_title clear">
                <a className="sq_more" href="//www.gyyx.cn/News/List/extend/1">查看更多</a>
                推广公告
            </h2>
            <ul className="sq_tggg_list sprlist">
            </ul>
        </div>
    </div>)
}
   
