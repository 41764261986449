import 'rc-pagination/assets/index.css'; 
import './css/style.scss';
import React,{useState} from 'react'; 
import Pagination from 'rc-pagination'; 
import { newsListUrl } from '../../../api/newsService';
import moment from 'moment';
import { useAxios } from '../../../utils/common';

const divItemRender = (current, type, element) =>  {
  if (type === 'prev') {
    return < div > 上一页 </div > ; 
  }
  if (type === 'next') {
    return < div > 下一页 </div > ; 
  }
  return element; 
}; 



const LPagination = (props) =>  {
   const typeId = props.typeId; 
    const pageSize = 20;
    const [pageIndex,setPageIndex] = useState(1);
    const {data} = useAxios(`${newsListUrl}?pageIndex=${pageIndex}&typeId=${typeId}`, 'get', {
        pageSize,
    });
    return (<div className ="PagenationNav">
      <ul className="list_u">
              {
          data?.data?.list.map(item=><li key={item.Code}><a href={"/News/Detail/"+ item.Code}>{item.Title}</a><span>{moment(item.ShowTime).format('YYYY-MM-DD')}</span></li>)
        }
        </ul>  
      <Pagination total={data?.data?.total} pageSize={pageSize} current={pageIndex} onChange={(current)=>{setPageIndex(current)}}  itemRender={divItemRender} />
            
    </div>)
}
  export default LPagination