/*
 * @Author: liujunhong
 * @Date: 2022/11/1
 * @Desc: 
 */

import {Foot, Header} from 'gyyx-component-lib';
import React from "react";
import Routes from '../../routes/routes'

const Layout = () => {

  return <>
    <Header/>
    <Routes/>
    <Foot footId='footer-front-www'/>
  </>

}

export default Layout



