/*
 * @Author: liujunhong
 * @Date: 2022/12/21
 * @Desc: 
 */

export const rulesMap = {
  require: (value) => {
    return value !== undefined && value.length !== 0
  },
  regexp: (value, pattern) => {
    return new RegExp(pattern).test(value)
  },
  'not_regexp': (value, pattern) => {
    return !new RegExp(pattern).test(value)
  },
  between: (value, min, max) => {
    return min <= value.length && value.length <= max
  },
  min: (value, min) => {
    return min < value.length
  },

}

export const rulesValid = (value, rules,) => {
  let valid = true;
  let msg = '';

  for (let idx = 0, length = rules.length; idx < length; idx++) {
    let item = rules[idx]
    if (!valid) {
      break
    }
    if (typeof item.rule === 'function') {
      valid = item.rule(value);
      msg = item.message;
      continue;
    }
    let name = item.rule.split(':');
    switch (name[0]) {
      case 'require':
        valid = rulesMap['require'](value);
        msg = item.message;
        break;
      case 'min':
        valid = rulesMap['min'](value);
        msg = item.message;
        break;
      case 'between':
        let splitVal = name[1].split(',')
        valid = rulesMap['between'](value, splitVal[0], splitVal[1]);
        msg = item.message;
        break;
      case 'regexp':
        valid = rulesMap['regexp'](value, name[1]);
        msg = item.message;
        break;
      case 'not_regexp':
        valid = !rulesMap['regexp'](value, name[1]);
        msg = item.message;
        break;
    }
  }
  return {valid, msg: valid ? '' : msg};
}

























