/*
 * @Author: liujunhong
 * @Date: 2022/12/20
 * @Desc: 
 */

import { DOWN_URL, REQUEST_URL } from "../consts";
import { ajaxRequest } from "../utils/common";


export const registerSmsOpen = () => {
  return ajaxRequest(`${REQUEST_URL}/v1/reg/register/isPersonalSmsOpen?param=clientpersonality`, 'get')
}


/**
 * 下载安装包
 * @returns {Promise<unknown>}
 */
export const getDownloadUrl = (params) => {
  return ajaxRequest(`${DOWN_URL}/download/urlauthencrypt`, 'get', {...params})
}