import React from 'react';
import TopNav from './components/TopNav.js';
import LeftNav from './components/LeftNav.js';
import RightNav from './components/RightNav.js';
import './components/css/style.scss';
export default (props)=>{
const typeId = props.match.path==="/news/list/Trends/1/"?2:3;
    return (
            <div className="news_page">
                <TopNav />
                <div className="mainBox">
                    <div className="main_cha">
                        <LeftNav />
                        <RightNav  typeId={typeId}/>
                    </div>
                </div>
            </div>
    )
}