
import './style.scss'


const TipPop = (props) => {
    return (
        <div className="tipPopBox">
            <div className="tip_box">
                <div className="close_pop" id='realname_pop_close_btn' onClick={() => props.setChildShowtipflag(false)}></div>
                <div className="tip_title"  id='realname_pop_message'>{props.title}</div>
                <div className="tip_button" id='realname_pop_surebtn' onClick={() => props.setChildShowtipflag(false)}>确认</div>
            </div>
        </div>
    )
}
export default TipPop;