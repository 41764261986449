/*
 * 社区内实名页面
 * @author wangaidi
 * @constructor 渲染页面
 */

import React, {useState, useEffect} from 'react';
import {ajaxRequest, useAxios} from '../../utils/common';
import {Captcha} from 'gyyx-component-lib';
import Utils from 'gyyx-base-lib'
import {handleCheck, BID_REAL_NAME_GAME} from './consts'
const UN_LOGIN_text = '请点击返回按钮重新登录'

const Index = () => {
    const [name, setName] = useState('');
    const [idcard, setIdcard] = useState('');
    const [account, setAccount] = useState('');
    const [nameError, setnameError] = useState('');
    const [idcardError, setIDError] = useState('');
    const [yzm, setYzm] = useState('visib');
    const [tip_err, setTipErr] = useState(false);
    const [msg, setMsg] = useState('');
    const [refreshCaptcha, setRefreshCaptcha] = useState(0);


    let url = window.location.href;
    const {data} = useAxios('//accountv5.gyyx.cn/about/account/game/sign', 'post', { //签名接口/
        signUrl: url,
    })

    useEffect(() => {
        if (data && data?.success) {
            setAccount(data.data)
        } else {
            setAccount(UN_LOGIN_text)
        }
    }, [data])


    const submit = function () {
        let exp = /^[\u4e00-\u9fa5\.·]{1,30}$/;
        let checkRes = handleCheck(Utils.checkIdCard(idcard))
        if (name.trim() === '') {
            setnameError('请输入姓名');
        } else if (name.trim().length < 2) {
            setnameError('请输入正确格式的姓名');
        } else if (!exp.test(name)) {
            setnameError('真实姓名请填写2~30个字')
        } else if (checkRes) {
            setnameError('');
            setIDError(checkRes);

        } else {
            setnameError('');
            setIDError('');
            //显示验证码弹层
            setYzm("visib2");
            setRefreshCaptcha(Math.random());
        }
    }
    //关闭验证码弹层
    const handleValidClose = () => {
        setYzm("visib");
        setRefreshCaptcha(Math.random());
    };

    /**
     * 点击提交验证码时，获取到用户输入和平衡码。用于和业务一块进行提交
     * @param captchaParm
     */
    const handleSubmit = (captchaParm) => {
        const newobj = {
            bid: BID_REAL_NAME_GAME,
            userName: name,
            cernumber: idcard,
            captcha: captchaParm.captcha,
            cookies: captchaParm.cookies,
            balanceCode: captchaParm.balanceCode,
            signUrl: window.location.href,
        };

        ajaxRequest('//accountv5.gyyx.cn/account/user/checkAccountRealName', 'post', newobj).then(res => {
            // 显示错误提示层
            setYzm("visib3");
            if (res.success) {
                setMsg("实名认证通过，但此认证只用于真人检测，不用于账号归属判定。");
                setTipErr(false);
            } else if (res.message.indexOf('验证码') > -1) {
                setTipErr(true);
                setMsg(res.message)
                setRefreshCaptcha(Math.random());
            } else if (res.message.indexOf('获取不到随机字符串') > -1) {
                setRefreshCaptcha(Math.random());
                setTipErr(true);
                setMsg('获取不到当前用户账号，请重新登陆');
            } else {
                setRefreshCaptcha(Math.random());
                setTipErr(true);
                setMsg(res.message);
                setRefreshCaptcha(Math.random());
            }
        }).catch((error) => {
            setYzm("visib3");
            setTipErr(true);
            setMsg(error.message);

        })
    };
    const BackFnShou = function (a) {

        setYzm("visib");
        setRefreshCaptcha(Math.random());
    }
    const BackFn = function () {
        setYzm("visib2");
        setRefreshCaptcha(Math.random());
    }
    const resultFn = function () {
        if (msg == "验证码错误") {
            BackFn();
        } else {
            setYzm("visib");
        }
    }


    return (
        <div className="game_real_name_main">
            <div className="form_box" style={{display: yzm == "visib" ? "block" : "none"}}>
                <div className="form">
                    <div className="form_item">
                        <label className="form_label">账号：</label>
                        <span id='input_gamerealname_account' className="form_account">{account}</span>
                    </div>
                    <div className="form_item">
                        <label className="form_label">姓名：</label>
                        <input id='input_gamerealname_name' onChange={(e) => {
                            setName(e.target.value)
                        }} value={name} placeholder='请输入姓名' className={`form_name ${nameError ? "err" : ""}`}
                               minLength={2} maxLength={30}/>
                        <span className="error"
                              style={{display: nameError ? 'inline-block' : 'none'}}>{nameError}</span>
                    </div>
                    <div className="form_item">
                        <label className="form_label">身份证：</label>
                        <input id='input_gamerealname_idcard' onChange={(e) => {
                            setIdcard(e.target.value)
                        }} value={idcard} placeholder='请输入身份证号码'
                               className={`form_idcard ${idcardError ? "err" : ""}`}
                               maxLength={18}/>
                        <span className="error" id="span_gamerealname_idcard"
                              style={{display: idcardError ? 'inline-block' : 'none'}}>{idcardError}</span>
                    </div>
                    <div className="form_item">
                        <label className="form_label form_label_sub">&nbsp;</label>
                        <input id="input_gamerealname_submit" onClick={submit} className='form_next_btn' readOnly disabled={account === UN_LOGIN_text}/>
                    </div>
                </div>
            </div>
            <div className="reminder" style={{display: yzm == "visib" ? "block" : "none"}}>
                <h1></h1>
                <div>温馨提示：</div>
                <p id='p_gamerealname_tit'>实名认证只用于防沉迷检测，不会替换用户三项注册资料，不能用于判定账号归属。</p>

            </div>


            <div className="captchaBox" style={{display: yzm == "visib2" ? 'block' : 'none'}}>
                <h2>请输入验证码完成登录</h2>
                <div id='div_gamerealname_captcha' className="closeBtn" onClick={BackFnShou}></div>
                <div className='capcon'>
                    <Captcha visible bId={BID_REAL_NAME_GAME} onClose={handleValidClose} onSubmit={handleSubmit}
                             refreshCaptcha={refreshCaptcha} getContainer={'.capcon'} mask={false}/>
                </div>
            </div>
            <div className={`resultBox ${tip_err ? "cur" : ""}`} style={{display: yzm == "visib3" ? 'block' : 'none'}}>
                <h2></h2>
                <div id='div_gamerealname_message' className="messge">{msg}</div>
                <div id='div_gamerealname_surebtn' className="sureBtn" onClick={resultFn}
                     style={{display: tip_err ? 'block' : 'none'}}></div>
            </div>


        </div>

    )
}

export default Index