import 'rc-pagination/assets/index.css'; 
import './css/style.scss';
import React,{useEffect,useRef} from 'react'; 
import { newsDetailsUrl } from '../../../api/newsService';
import moment from 'moment';
import { useAxios } from '../../../utils/common';




const DetailsPassage = (props) =>  {
 
    const code = props.code;
    const wrapEle = useRef(null);

    const {data} = useAxios(`${newsDetailsUrl}?code=${code}`, 'get');
    const name = data?.data.TypeName;
    
    useEffect(()=>{
      
      if(data){
        if(data?.data.Url){
         window.location.href=data?.data.Url
        }
        wrapEle.current.innerHTML = data.data.Content;
      }
    },[data])
    return (<div><div className="cont_now">当前位置：<span><a href={name==="光宇动态"?"//www.gyyx.cn/news/list/Trends/1/":"//www.gyyx.cn/News/List/extend/1"}>新闻中心</a></span> &gt; <span>{name==="光宇动态"?'光宇动态':'推广公告'}</span> &gt; <span>{data?.data.Title}</span></div><div className ="list_c">
            <h2>{data?.data.Title}<br/><span>发布于{moment(data?.data.ShowTime).format('YYYY-MM-DD')}</span></h2>
            <div ref={wrapEle}> {data?.data.Content}</div>
    </div></div>)
}
  export default DetailsPassage