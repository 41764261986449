import News from './page/News/News';
import Details from './page/News/Details';
import List from './oa/Index/List';
import RealName from './page/realName/index'
import Home from './page/home';


const routes = [
  //{ path: '/news/list/Trends/:id', name: 'news', exact:true, component: News },//可以这样区分根据id，通过传不同的值展示不同的页面
  {path: '/news/list/Trends/1/', name: 'news', exact: true, component: News},
  {path: '/News/List/extend/1', name: 'news', exact: true, component: News},
  {path: '/News/Detail/:id', name: 'details', exact: true, component: Details},
  // { path:'/Resource/Agreement',name:'agreement',exact:true, component: Agreement},
  // { path:'/Resource/Agreement.html',name:'agreement',exact:true, component: Agreement},
  // { path:'/Resource/Agreementwap.html',name:'agreement',exact:true, component: Agreement},
  {path: '/office/index', name: 'oalist', component: List},
  {path: '/realname/index', name: 'RealName', component: RealName},
  {path: '/', name: 'home', component: Home},
  // { path: '/', name: 'Product', component: Product },
]
export default routes;