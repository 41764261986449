/**
 * 社区内实名页面
 * @author wangaidi
 * @constructor 渲染页面
 */

import {useState, useEffect} from 'react';
import {getCookie} from '../../utils/common';
import TipPop from '../../components/TipPopBox'
import {Captcha} from 'gyyx-component-lib';
import {RealName} from 'account-net-sdk';
import {handleCheck, BID_REAL_NAME, BID_REAL_NAME_GAME} from './consts'
import Utils from 'gyyx-base-lib'
import './style.scss'


const RealNamePage = () => {
  const [name, setName] = useState('');
  const [idcard, setIdcard] = useState('');
  const [account, setAccount] = useState('');

  // 错误弹层 默认不显示
  const [showtipflag, setShowtipflag] = useState(false);
  const [tiptitle, setTiptitle] = useState('');

// 默认不显示验证码层
    const [showValid, setShowValid] = useState(false);

    useEffect(() => {
        const userAccount = getCookie('GYYX_AUTH_AGAIN_REALNAME_ACCOUNT');
        if (!userAccount) {
            //  window.location.href = '//www.gyyx.cn';
        } else {
            setAccount(userAccount);
        }

    }, []);

  //关闭验证码弹层
  const handleValidClose = () => {
    setShowValid(false);
  };

    // 点击提交验证码时，获取到用户输入和平衡码。用于和业务一块进行提交

  const handleSubmit = (captchaParm) => {
    const newobj = {
      bid: BID_REAL_NAME_GAME,
      userName: name,
      cernumber: idcard,
      captcha: captchaParm.captcha,
      cookies: captchaParm.cookies,
      balanceCode: captchaParm.balanceCode,
      signUrl: null
    };
    RealName(newobj).then((res) => {
      console.log(res)
      if (res.data.success) {
        setShowtipflag(true)
        setTiptitle('实名认证通过，但此认证只用于真人检测，不用于账号归属判定。')
        setTimeout(() => {
          window.location.href = "//www.gyyx.cn/";
        }, 2000);
      } else if (res.data.message.indexOf('验证码') > -1) {
        setShowtipflag(true)
        setTiptitle(res.data.message);
      } else if (res.data.message.indexOf('获取不到随机字符串') > -1) {
        setShowValid(false);
        setShowtipflag(true)
        setTiptitle('获取不到当前用户账号，请重新登陆')
      } else {
        setShowValid(false);
        setShowtipflag(true)
        setTiptitle(res.data.message)
      }
    }).catch((error) => {
      console.log(error)
      setShowtipflag(true)
    })

        setShowValid(false);
    };


    const submit = () => {
        let exp = /^[\u4e00-\u9fa5\.·]{2,30}$/;

        let checkRes = handleCheck(Utils.checkIdCard(idcard))

        if (name.trim() === '') {
            setShowtipflag(true)
            setTiptitle('请输入姓名')
        } else if (name.trim().length < 2) {
            setShowtipflag(true)
            setTiptitle('请输入正确格式的姓名')
        } else if (!exp.test(name)) {
            setShowtipflag(true)
            setTiptitle('您输入的格式错误，真实姓名请填写2~30个字')
        } else if (idcard.trim() === '') {
            setShowtipflag(true)
            setTiptitle('请输入正确的身份证号码')
        } else if (checkRes) {
            setShowtipflag(true);
            setTiptitle(checkRes);
        } else {
            setShowtipflag(false)
            //弹出验证码弹层
            setShowValid(true);
        }
    }
    return (

    <div className="real_name_main">
      <div className="form_box">
        <div className="form">
          <div className="form_item">
            <label className="form_label">账号：</label>
            <span className="form_account" id='input_realname_account'>{account}</span>
          </div>
          <div className="form_item">
            <label className="form_label">姓名：</label>
            <input id='input_realname_name' onChange={(e) => {
              setName(e.target.value)
            }} value={name} placeholder='请输入姓名' className='form_name' minLength={2} maxLength={30}/>
          </div>
          <div className="form_item">
            <label className="form_label">身份证：</label>
            <input id='input_realname_idcard' onChange={(e) => {
              setIdcard(e.target.value)
            }} value={idcard} placeholder='请输入身份证号码' className='form_idcard' maxLength={18}/>
          </div>
          <div className="form_item">
            <label className="form_label">&nbsp;</label>
            <input id='input_realname_submit' value='验证' onClick={submit} className='form_next_btn'
                   readOnly/>
          </div>
        </div>
      </div>
      <div className="reminder">
        <div>温馨提示：</div>
        <p>实名认证只用于防沉迷检测，不会替换用户三项注册资料，不能用于判定 账号归属。</p>
      </div>
      <Captcha bId={BID_REAL_NAME} onClose={handleValidClose} onSubmit={handleSubmit}
               visible={showValid}/>

      {showtipflag && <TipPop title={tiptitle} setChildShowtipflag={setShowtipflag}></TipPop>}

    </div>
  );
}


export default RealNamePage;
