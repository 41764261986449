/*
 * @Author: liujunhong
 * @Date: 2023/3/13
 * @Desc: 首页
 */
import React, { useEffect, useState } from 'react';
import './style.scss';
import { MAP_URL_PAGE } from '../../consts';
import { BANNER_LIST, GAME_LIST, INFORMATION_LIST, MAP_STATIC_URL } from './consts';
import classNames from 'classnames';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GyyxBaseLib from "gyyx-base-lib";
import { getDownloadUrl } from "../../servers/register";
import { Captcha } from "gyyx-component-lib";
import { BID_REGISTER_GAME } from "../../consts/register";

const settings = {
  dots: true, infinite: true, arrows: false, draggable: false, slidesToScroll: 1, slidesToShow: 1, autoplay: true,
};

const Index = () => {
  const [curGame, setCurGame] = useState({});
  const [infoList, setInfoList] = useState([]);
  const [curIndex, setCurIndex] = useState(0);
  const [bannerList, setBannerList] = useState([])
  const [gameList, setGameList] = useState([])
  const [from, setFrom] = useState('in')
  const [captchaVisible, setCaptchaVisible] = useState(false)
  const [bId, setBId] = useState('')
  const [refreshCaptcha, setRefreshCaptcha] = useState(0);
  const [captchaData, setCaptchaData] = useState({})
  const [downData, setDownData] = useState({
    netType: 1, typeId: 868
  })


  const setInformationList = (list) => {
    const arr = [];
    for (let i = 0; i < list.length; i += 3) {
      arr.push(list.slice(i, i + 3));
    }
    return arr
  }

  const init = () => {
    GyyxBaseLib.loadJs({
      src: MAP_STATIC_URL.BANNER, cb: () => {
        if (window.BANNER_LIST) {
          setBannerList(window.BANNER_LIST)
        } else {
          setBannerList(BANNER_LIST)
        }
      }
    })

    GyyxBaseLib.loadJs({
      src: MAP_STATIC_URL.INFORMATION, cb: () => {
        if (window.INFORMATION_LIST) {
          let list = setInformationList(window.INFORMATION_LIST)
          setInfoList(list)
        } else {
          let list = setInformationList(INFORMATION_LIST)
          setInfoList(list)
        }
      }
    })

    GyyxBaseLib.loadJs({
      src: MAP_STATIC_URL.GAME, cb: () => {
        if (window.GAME_LIST) {
          setGameList(window.GAME_LIST)
          setCurGame(window.GAME_LIST[0])
        } else {
          setCurGame(GAME_LIST[0])
          setGameList(GAME_LIST)
        }
      }
    })
  }


  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (captchaData?.captcha) {
      handleDownload(downData.netType, downData.typeId)
    }
  }, [captchaData?.captcha])

  const handleClick = (item) => {
    setCurGame(item);
  };


  const handlePrevClick = () => {
    if (curIndex === 0) {
      return;
    }
    let index = curIndex;
    index = index - 1;
    setCurIndex(index);
    setFrom('out')

  };

  const handleNextClick = () => {
    if (curIndex === infoList.length - 1) {
      return;
    }
    let index = curIndex;
    index = index + 1;
    setCurIndex(index);
    setFrom('in')
  };

  const handleInfoClick = (item) => {
    window.open(item.href)
  }

  /**
   * 处理下载app
   */
  const handleDownload = (netType, typeId) => {

    let params = {
      captchaCode: captchaData?.captcha || '', fileName: '', netType, typeId
    }
    if (captchaData?.captcha) {
      params = {...params, balanceCode: captchaData?.balanceCode}
    }

    getDownloadUrl(params).then(res => {
      console.log(res)
      if (res.retCode === "SUCCESS") {
        captchaData?.captcha && setCaptchaVisible(false)
        window.location.href = res.data;
      } else if (res.retCode === "CAPTCHA_ERROR") {
        // 验证码错误
        setRefreshCaptcha(Math.random());
      } else if (res.retCode === "PARAM_ERROR" && res.data === "needCaptcha") {
        // 需要验证码
        setBId(res.message)
        setCaptchaVisible(true)
      } else {
        // 弹错误提示
        alert(res.message);
        setCaptchaVisible(false)
      }
    })
  }

  const handleCaptchaSubmit = (data) => {
    setCaptchaData(data)
  }


  return <div className="gy_home_wrap">

    <div className="gy_home_banner_wrap">
      <Slider {...settings} className="gy_home_swiper_container">
        {bannerList.map(item => <div key={item.id} className="gy_home_swiper_slide">

          {item.hrefDetail && <a href={item.hrefDetail} target='_blank'><img src={item.img} alt=""/></a>}
          {!item.hrefDetail && <img src={item.img} alt=""/>}
          <div className='gy_home_banner_content'>
            {/*  {item.isShowDetailBtn && <span className='gy_home_banner_detail'>*/}
            {/*  <a href={item.hrefDetail} target="_blank">{item.detailBtnText}</a>*/}
            {/*</span>}*/}
            {item.isShowCode && <div className='gy_home_banner_info'>
              <img src={item.hrefCodeDown} alt=""/>
              <img src={item.hrefCodeHome} alt=""/>
              <div className='gy_home_banner_btn_wrap'>
                  <span className='gy_home_banner_btn_down'>
                    {
                      item.downNetType ? <span className='down_android' onClick={() => {
                          console.log(item.downNetType, item.downTypeId)

                          handleDownload(item.downNetType, item.downTypeId)
                        }}>{item.textDown}</span> :
                        <a id='btn_download_game' href={item.hrefDown} target='_blank'>{item.textDown}</a>
                    }
                  </span>
                <span className='gy_home_banner_btn_home'>
                     <a id='btn_navigate_home' href={item.hrefHome} target='_blank'>{item.textHome}</a>
                  </span>
              </div>
            </div>}
          </div>

        </div>)}

      </Slider>
      <div className="gy_home_menu_wrap">
        <div className="gy_home_menu_mask">

        </div>
        <div className="gy_home_menu_list main">
          <a href={MAP_URL_PAGE.REGISTER} target="_blank" id="btn_home_menu_register"
             className="gy_home_menu_register gy_home_menu_btn"></a>
          <a href={MAP_URL_PAGE.PAY} target="_blank" id="btn_home_menu_pay"
             className="gy_home_menu_game_pay gy_home_menu_btn"></a>
          <a href={MAP_URL_PAGE.QI_BAO} target="_blank" id="btn_home_menu_qi_bao" className="gy_home_menu_item"></a>
          <a href={MAP_URL_PAGE.ACCOUNT} target="_blank" id="btn_home_menu_account"
             className="gy_home_menu_item"></a>
          <a href={MAP_URL_PAGE.FA_Q} target="_blank" id="btn_home_menu_popularize"
             className="gy_home_menu_item "></a>
          <a href={MAP_URL_PAGE.CHANGE} target="_blank" id="btn_home_menu_conversion"
             className="gy_home_menu_item "></a>
          <a href={MAP_URL_PAGE.DAO} target="_blank" id="btn_home_menu_service"
             className="gy_home_menu_item "></a>
        </div>
      </div>
    </div>

    <div className="gy_home_information_wrap ">

      <div className="main gy_home_information_in">
        <div className="gy_home_information_arrows">
          <span className="gy_home_information_arrows_l" onClick={handlePrevClick}>
          </span>
          <span className="gy_home_information_arrows_r" onClick={handleNextClick}>
          </span>
          <div>
          </div>
        </div>

        <div className="gy_home_information_list">
          {0 < infoList.length && infoList[curIndex].map(item => <div
            className={classNames("gy_home_information_item", from)}
            key={item.id}
            onClick={() => handleInfoClick(item)}>
            <img className="gy_home_information_item_img" src={item.img} alt=""/>
            <div className="gy_home_information_item_card">
              <h2 className="gy_home_information_item_title">{item.title}</h2>
              <p className="gy_home_information_item_content">
                {item.content}
              </p>
            </div>
          </div>)}
        </div>
      </div>
    </div>

    <div className="gy_home_hot_game_wrap">
      {0 < Object.keys(curGame).length && <div className="gy_home_hot_game_in"
                                               style={{background: `url(${curGame.gameBg}) no-repeat center`}}>
        <div className="main gy_home_hot_game_info">
          <img className="gy_home_hot_game_logo" src={curGame.gameLogo} alt=""/>
          <div className="gy_home_hot_game_content">
            <span className="gy_home_hot_game_name">{curGame.gameName}</span>
            <p className="gy_home_hot_game_intro">{curGame.gameIntro}</p>
            {curGame.hrefText && <span className="gy_home_hot_game_link"><a href={curGame.href}
                                                                            target="_blank">{curGame.hrefText}</a></span>}
          </div>
        </div>

      </div>}

      <div className="gy_home_hot_game_list">
        <div className="main gy_home_hot_game_list_in">
          {gameList && gameList.map(item => <div key={item.gameName} onClick={() => handleClick(item)}
                                                 className={classNames('gy_home_hot_game_item', {cur: item.gameName === curGame.gameName})}>
            <img src={item.gameSign} alt=""/>
            <span>{item.gameName}</span>
          </div>)}
        </div>
      </div>
    </div>

    <div className="gy_home_system_wrap">
      <a className='gy_home_system_link' href={MAP_URL_PAGE.UNDERAGE} target='_blank'></a>
    </div>
    {bId && <Captcha
      onSubmit={handleCaptchaSubmit}
      bId={bId}
      refreshCaptcha={refreshCaptcha}
      onClose={() => setCaptchaVisible(false)}
      visible={captchaVisible}
    />}

  </div>;
};

export default Index;