/*
 * @Author: liujunhong
 * @Date: 2022/12/26
 * @Desc: 完善信息
 */

import React, { useState } from 'react'
import './style.scss'
import Form, { Field as FormItem } from "../../components/CustomFormControl/Form";
import Input from "../../components/CustomFormControl/Input";
import { Captcha } from "gyyx-component-lib";
import { BID_COMPLETE_INFO } from "../../consts/register";
import '../clientRegister/captcha.scss'
import { ACCOUNT_RULES, CER_NUM_RULES, E_MAIL_RULES, PW_RULES, TRUE_NAME_RULES } from "./consts";
import { ajaxRequest } from "../../utils/common";
import { REQUEST_URL } from '../../consts/index'
import md5 from "js-md5";


const Index = () => {
  const [form] = Form.useForm();
  const [captchaVisible, setCaptchaVisible] = useState(false)
  const [validCbData, setValidCbData] = useState({})
  const [formItem, setFormItem] = useState({})
  const [errorTips, setErrorTips] = useState('')
  const [completeInfo, setCompleteInfo] = useState(false)


  const handleCaptchaSubmit = (props) => {
    const {captcha, cookies, balanceCode} = props
    setValidCbData({...props});
    setCaptchaVisible(false)
    requestSubmit({captcha, cookies, balanceCode})
  }

  const handleValidClose = () => {
    setCaptchaVisible(false)
  }

  const handleSubmit = () => {
    const error = form.validate()
    if (Object.keys(error).length) {
      setFormItem({...error})
      return
    }
    setFormItem({})
    if (!Object.keys(validCbData).length) {
      setCaptchaVisible(true)
      return
    }
    requestSubmit({...validCbData})
  };

  const requestSubmit = (props) => {
    const {
      account,
      password, trueName, cerNumber, mail
    } = form.getFieldsValue()
    let params = {
      account,
      password: md5(password.trim()),
      trueName,
      cerNumber,
      mail,
      bid: BID_COMPLETE_INFO,
      ...props
    }


    ajaxRequest(`${REQUEST_URL}/about/account/gameUpdateThreeInfo`, 'post', params).then(res => {
      console.log(res)
      setValidCbData({})
      if (!res.success) {
        setErrorTips(res.message)
        return
      }
      setCompleteInfo(true)
    })
  }


  return <div className='complete_info_wrap'>
      {completeInfo ? <div className='complete_info_success' id='complete_info_success'>
        <h3 className='complete_info_title'>恭喜您，三项注册资料提交成功 </h3>
        <h3 className='complete_info_title'>请您点击右上角关闭页面并登陆游戏 </h3>
      </div> : <div>
        {<p className='complete_info_tips'>{errorTips}</p>}
        <h3 className='complete_info_title'>完善账号资料</h3>
        <p className='complete_info_intro'>（注：本页面不支持TAB键换行，请使用鼠标点击）</p>
        <div className='complete_info_form_wrap'>
          <Form form={form}>
            <FormItem name="account" label='社区账号' rules={ACCOUNT_RULES}>
              <Input msg={formItem?.account} id='input_complete_info_account' msgId='text_complete_info_account'/>
            </FormItem>
            <FormItem name="password" label='社区密码' rules={PW_RULES}>
              <Input type='password' msg={formItem?.password} id='input_complete_info_password'
                     msgId='text_complete_info_password'/>
            </FormItem>
            <FormItem name="trueName" label='注册姓名' rules={TRUE_NAME_RULES}>
              <Input msg={formItem?.trueName} id='input_complete_info_trueName' msgId='text_complete_info_trueName'/>
            </FormItem>
            <FormItem name="cerNumber" label='注册身份证' rules={CER_NUM_RULES}>
              <Input msg={formItem?.cerNumber} id='input_complete_info_cerNumber' msgId='text_complete_info_cerNumber'/>
            </FormItem>
            <FormItem name="mail" label='注册邮箱' rules={E_MAIL_RULES}>
              <Input msg={formItem?.mail} id='input_complete_info_mail' msgId='text_complete_info_mail'/>
            </FormItem>
            <span className='complete_info_submit' id='input_complete_info_submit' onClick={handleSubmit}></span>
          </Form>
        </div>
        <Captcha
          onSubmit={handleCaptchaSubmit}
          bId={BID_COMPLETE_INFO}
          onClose={handleValidClose}
          visible={captchaVisible}
        />

      </div>}
  </div>

}

export default Index
