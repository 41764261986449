import React from 'react'
import FieldContext from './FieldContext'
import './style.scss'

const Field = (props) => {
  const {children, name} = props;

  const {
    getFieldValue,
    setFieldsValue,
    registerField
  } = React.useContext(FieldContext);

  const [, forceUpdate] = React.useReducer((x) => x + 1, 0);

  React.useLayoutEffect(() => {
    return registerField({
      props,
      onStoreChange: forceUpdate,
    });
  }, []);

  const getControlled = () => {
    return {
      value: getFieldValue(name), //"omg", // get state
      onChange: (e) => {
        const newValue = e.target.value;
        // set state
        setFieldsValue({[name]: newValue});
      },
      onChangeInput: (e) => {
        const newValue = e.target.value;
        console.log(newValue)
        setFieldsValue({[name]: newValue});
      },
    };
  };

  return React.cloneElement(children, getControlled());
}


const Index = (props) => {
  const {label, children, name, rules} = props;
  return <div className='form_item_wrap'>
    {label && <label>{label}</label>}
    <Field children={children} name={name} rules={rules}/>
  </div>
}


export default Index
