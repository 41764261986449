import React from 'react';
import '../News/components/css/style.scss';

export default () => {
  return (
    <div className="agreement_page">
      <div className="main">
        <div className="info">
          <h2><a id="Terms"></a>光宇通行证用户协议</h2>
          <h3><strong>前言：</strong></h3>
          <p>
            <strong>1.《光宇通行证用户协议》（以下简称“本协议”）由您与光宇游戏服务提供方共同缔结，本协议具有合同效力。请您务必审慎阅读、充分理解各条款内容，特别是其中免除或者限制光宇游戏责任的条款（该等条款通常含有“不负任何责任”、“无义务”、“不保证”等词汇）、限制用户权利的条款（该等条款通常含有“不得”、“不应”、“无权”等词汇）、法律适用和争议解决条款，这些以及开通或使用某项服务的单独协议。</strong>
          </p>
          <p>前述免责、限制及争议解决方式和管辖条款可能以黑体加粗、颜色标记或其他合理方式提示您注意，包括但不限于本协议第二条、第三条、第四条、第五条、第八条等相关条款，您对该等条款的确认将可能导致您在特定情况下的被动、不便、损失，请您在确认同意本协议之前或在使用光宇游戏服务之前再次阅读本协议条款。</p>
          <p><strong>2.除非您已阅读并接受本协议所有条款，否则您无权使用光宇游戏服务。如您不同意本协议的任何条款，则不得注册、使用光宇游戏服务。</strong>如果您对本协议或光宇游戏服务有意见或建议，可与光宇游戏客户服务部门联系，我们会给予您必要的帮助。您点击同意、接受或下一步，或您注册、使用光宇游戏服务均视为您已阅读并同意签署本协议。
          </p>
          <p><strong>3.请您理解，光宇游戏提供的相关产品仅面向成年人提供服务，如果您未满18周岁的，请停止使用光宇游戏的任何产品及服务。</strong></p>
          <ul>
            <li>
              <h4><strong>一、【定义】</strong></h4>
              <p>1.1 本协议：指本协议正文《光宇通行证用户协议》、《光宇保护隐私条款》、游戏规则及其修订版本。上述内容一经正式发布，即为本协议不可分割的组成部分。</p>
              <p>1.2 游戏规则：指光宇游戏服务提供方不时发布并修订的关于光宇游戏的用户守则、玩家条例、游戏公告、提示及通知等内容。</p>
              <p>1.3 光宇游戏服务提供方：指向您提供光宇游戏及其服务的光宇在线科技有限责任公司，在本协议中简称为“光宇游戏”。</p>
              <p>1.4
                光宇游戏：指由光宇游戏负责运营的游戏的统称，包括计算机客户端游戏、网页游戏、HTML5游戏（H5游戏）、移动终端游戏、电视端游戏以及其他形式的游戏；光宇游戏可能以软件形式提供，这种情况下，光宇游戏还包括该相关软件及相关文档。</p>
              <p>1.5 光宇游戏服务：指光宇游戏向您提供的与游戏相关的各项在线运营服务。</p>
              <p>1.6 您：又称“玩家”或“用户”，指被授权使用光宇游戏及其服务的自然人。</p>
              <p>1.7 游戏数据：指您在使用光宇游戏过程中产生的被服务器记录的各种数据，包括但不限于角色数据、虚拟物品数据、行为日志、购买日志等数据。</p>
            </li>
            <li>
              <h4><strong>二、【游戏账号】</strong></h4>
              <p><strong>2.1
                用户承诺以其真实身份注册光宇游戏通行证，并保证所提供的个人身份资料信息真实、完整、有效，依据法律规定和本协议约定对所提供的信息承担相应的法律责任，您同意光宇游戏可将您提交的实名信息提交给合法留存您信息的国家有权机关、金融机构、支付机构和有关组织对您提交的实名信息进行核验。请您理解，根据相关实名认证的法律规定，您最多只能注册10个游戏账号，所有账号的游戏行为均归属于您一人。</strong>如您未符合本条规定的身份要求，光宇游戏有权采取本协议第5.7条第二款规定的一种或多种处理措施，并可公布处理结果，因此造成的一切后果由您自行承担，且光宇游戏有权要求您赔偿因此给光宇游戏造成的所有损失。
              </p>
              <p>2.2
                您充分理解并同意，光宇游戏有权审查用户注册所提供的身份信息是否真实、有效，并应积极地采取技术与管理等合理措施保障用户账号的安全、有效；用户有义务妥善保管其账号及密码，并正确、安全地使用其账号及密码。<strong>鉴于网络服务的特殊性，光宇游戏不审核是否用户本人使用该组帐号及密码，仅审核帐号及密码是否与数据库中保存的一致，只要任何人输入的帐号及密码与数据库中保存的一致，即可凭借该组帐号及密码登陆游戏，所以即使用户认为其所有的帐号登陆游戏的行为并非其本人所为，光宇游戏将不承担因此而产生的任何责任。</strong>
              </p>
              <p><strong>2.3
                若您发现有他人冒用或盗用您的游戏账号及密码、或任何其他未经您合法授权使用的情形时，应立即以光宇游戏要求的有效方式通知光宇游戏并告知光宇游戏需采取的措施。</strong>您通知光宇游戏时，应提供与您注册身份信息相一致的个人有效身份信息，光宇游戏收到您的有效请求并核实身份后，会根据您的要求或结合具体情况采取相应措施（包括但不限于暂停该账号的登录和使用等），光宇游戏因根据您的请求采取相应措施而造成您及其他用户损失的，由您自行承担。若您没有提供有效身份信息或您提供的个人有效身份信息与所注册的身份信息不一致的，光宇游戏有权拒绝您的请求，因此造成您损失的，由您自行承担。
              </p>
              <p>2.4
                用户有义务妥善保管其账号及密码，并正确、安全地使用其账号及密码。用户未尽上述义务导致账号密码遗失、账号被盗等情形而给自身造成损害的，由用户自己承担由此产生的直接和间接损失。包括但不限于(i)
                光宇游戏因技术障碍或失窃玩家未充分举证证明账号密码遗失、被盗是他人故意行为，导致光宇游戏无法或不予找回失窃账号给用户造成的损失（ii）光宇游戏找回了失窃账号但不保证账号内的全部物品安全而导致的损失（iii）失窃账号内的物品经过一次或多次流转被其他用户合法获得，无法找回而导致的损失等。</p>
              <p><strong>2.5
                您充分理解并同意，为高效利用服务器资源，如果您超过180天未使用游戏账号登录光宇游戏，光宇游戏有权在提前通知的情况下，对该账号及其账号下的游戏数据及相关信息采取处置措施。</strong>光宇游戏的处置措施包括但不限于，清除该用户在光宇游戏平台及数据库中的全部记录（包括但不限于角色信息、等级物品、点卡、积分信息等）、冻结或删除该用户帐号。用户帐号被删除时，其帐号所有资料及记录将被同时删除。对于连续180天未登陆而导致帐号被冻结或删除的用户，光宇游戏将不予任何赔偿或补偿。
              </p>
              <p>2.6
                游戏账号是光宇游戏按照本协议授权您用于登录、使用光宇游戏及相关服务的标识，其所有权属于光宇游戏。您仅根据本协议以及光宇游戏为此发布的专项规则享有游戏账号的使用权。<strong>您不得将游戏账号以任何方式提供给他人使用，包括但不限于不得以买卖、赠与、互易、租赁、继承等方式提供给他人作包括但不限于直播、录制、代打代练等商业性使用。</strong>否则，因此产生任何法律后果及责任均由您自行承担，且光宇游戏有权对您的游戏账号采取包括但不限于警告、限制或禁止使用游戏账号全部或部分功能、封号直至注销、删除游戏账号及游戏数据及其他相关信息处理措施，因此造成的一切后果由您自行承担。
              </p>
              <p>2.7
                在光宇游戏提供游戏账号注销功能情形下，如您需要注销游戏账号，可按照该游戏官方提供的账号注销指引进行操作，并应保证满足游戏官方公布的有关游戏账号注销的相关条件，同时同意游戏官方公布的游戏账号注销协议和其他有关规则。</p>
              <p>同时也特别提示您，
                如您注销游戏账号，该游戏账号下的相关游戏收益也将会被清除。据此，在您注销本游戏账号前请您确保已妥善处理该游戏账号下的游戏收益或相关的游戏收益已结清。一旦您注销本游戏账号，如届时该游戏账号下还存在游戏收益的（包括在本游戏使用期间已产生的及未来可能产生的游戏收益），光宇游戏有权对该游戏账号下的全部游戏收益做清除处理，因此产生的后果由您自行承担。前述游戏收益包括但不限于：游戏会员权益、等级；与游戏角色成长升级相关的所有数据（包括但不限于经验值、荣誉值、声望值、称号等）；尚未使用的网络游戏虚拟货币、虚拟道具及其他虚拟物品（如卡券、金币、钻石、道具及其他等）；已经购买但未到期或未使用完的增值服务；已产生但未消耗完毕的其他游戏收益或未来预期的游戏收益等。</p>
            </li>
            <li>
              <h4><strong>三、【用户信息收集、使用及保护】</strong></h4>
              <p><strong>3.1
                您同意并授权光宇游戏为履行本协议之目的收集您的用户信息，这些信息包括您实名注册信息、您游戏账号下的游戏数据以及其他您在使用光宇游戏服务的过程中向光宇游戏提供或光宇游戏基于安全、用户体验优化等考虑而需收集的信息，光宇游戏对您的用户信息的收集将遵循本协议及相关法律的规定。</strong>
              </p>
              <p>3.2 您充分理解并同意：光宇游戏或其合作的第三方可以根据您的用户信息，通过短信、电话、邮件等各种方式向您提供关于光宇游戏的活动信息、推广信息等各类信息。</p>
              <p>3.3
                您知悉并同意，如国家有权机关依法要求光宇游戏协助调查您游戏账号（包括实名身份信息）及游戏相关数据（如充值、消费、交易等数据）等信息的，光宇游戏可将前述相关信息提供给国家有权机关。</p>
              <p>3.4
                您应对通过光宇游戏及相关服务了解、接收或可接触到的包括但不限于其他用户在内的任何人的个人信息予以充分尊重，您不应以搜集、复制、存储、传播或以其他任何方式使用其他用户的个人信息，否则，由此产生的后果由您自行承担。</p>
              <p>3.5
                保护用户信息及隐私是光宇游戏的一项基本原则。除本协议另有规定外，光宇游戏服务对用户信息收集、使用及保护等将遵循《光宇保护隐私条款》等协议规定。除法律另有规定外，如您已同意或授权光宇游戏依据前述协议或规定收集、使用或处理涉及您的个人信息的，在您撤回该同意或授权后，不影响光宇游戏在此之前已基于您的同意或授权所进行的个人信息的收集、使用或处理，光宇游戏对此前相关个人信息收集、使用或处理继续有效。</p>
            </li>
            <li>
              <h4><strong>四、【光宇游戏服务】</strong></h4>
              <p>4.1 在您遵守本协议及相关法律法规的前提下，光宇游戏给予您一项个人的、不可转让及非排他性的许可，以使用光宇游戏服务。您仅可为非商业目的使用光宇游戏服务，包括：</p>
              <ol>
                <li>（1）接收、下载、安装、启动、升级、登录、显示、运行和/或截屏光宇游戏；</li>
                <li>（2）创建游戏角色，设置网名，查阅游戏规则、用户个人资料、游戏对局结果，开设游戏房间、设置游戏参数，在游戏中购买、使用游戏道具、游戏装备、游戏币等，使用聊天功能、社交分享功能；</li>
                <li>（3）使用光宇游戏支持并允许的其他某一项或几项功能。</li>
              </ol>
              <p>4.2 您在使用光宇游戏服务过程中不得未经光宇游戏许可以任何方式录制、直播或向他人传播光宇游戏内容，包括但不限于不得利用任何第三方软件进行网络直播、传播等。</p>
              <p>4.3 本条及本协议其他条款未明示授权的其他一切权利仍由光宇游戏保留，您在行使这些权利时须另外取得光宇游戏的书面许可。</p>
              <p><strong>4.4
                如果您违反本协议任何约定的，光宇游戏有权采取本协议第5.7条第二款规定的一种或多种处理措施，并可公布处理结果，且（/或）有权要求您赔偿因您违约行为而给光宇游戏造成的所有损失。</strong>
              </p>
              <p>4.5
                您充分理解并同意，光宇游戏有权依合理判断对违反有关法律法规或本协议规定的行为进行处理，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应独自承担由此而产生的一切法律责任。</p>
              <p>4.6
                您充分理解并同意，因您违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担责任；光宇游戏因此遭受损失的，您也应当一并赔偿。</p>
              <p><strong>4.7
                您充分理解并同意：游戏装备、游戏币及其他游戏道具等是光宇游戏服务的一部分，光宇游戏在此许可您依本协议而获得其使用权。您购买、使用游戏装备、游戏币及其他游戏道具等应遵循本协议、游戏具体规则的要求；同时，游戏装备、游戏币及其他游戏道具等可能受到一定有效期限的限制，即使您在规定的有效期内未使用，除不可抗力或可归责于光宇游戏的原因外，一旦有效期届满，将会自动失效。</strong>
              </p>
              <p>您充分理解并同意：为更好地向用户提供光宇游戏服务，光宇游戏有权对游戏相关内容（包括但不限于游戏角色、游戏装备及其他游戏道具的设计、性能及相关数值设置等）作出调整、更新或优化，该等更新或优化不被视为对您游戏权益的损害。</p>
              <p>4.8
                您充分理解并同意：为保障您游戏账号安全，为营造公平、健康及安全的游戏环境，在您使用光宇游戏服务的过程中，在不违反相关法律规定情况下，光宇游戏可以通过技术手段了解您终端设备的随机存储内存以及与光宇游戏同时运行的相关程序。一经发现有任何未经授权的、危害光宇游戏服务正常运营的相关程序，光宇游戏可以收集所有与此有关的信息并采取合理措施予以打击。</p>
              <p>4.9 您充分理解并同意：为了保证您及其他用户的游戏体验，光宇游戏有权转移或者清除光宇游戏服务器上存储的一些过往的游戏数据。</p>
              <p><strong>4.10
                光宇游戏向用户提供游戏服务本身属于商业行为，光宇游戏产品和服务的收费信息以及有关的资费标准、收费方式、购买方式或其他有关资费政策的信息，光宇游戏公司将会在具体游戏产品网站上作出说明，用户有权自主决定是否根据光宇游戏自行确定的收费项目</strong>（包括但不限于购买游戏内的虚拟道具的使用权以及接受其他增值服务等各类收费项目）及收费标准支付相应的费用，以获得相应的游戏服务。如您不按相应标准支付相应费用的，您将无法获得相应的游戏服务。
              </p>
              <p>您知悉并同意：光宇游戏公司将有权决定游戏产品和服务的资费标准，光宇游戏公司可能会就不同的产品和服务制定不同的资费标准，也可能按照光宇游戏公司所提供的产品和服务的不同阶段确定不同的资费标准。<strong>收费项目、收费标准的改变调整是一种正常的商业行为，您不得因为收费项目或收费标准的改变、调整而要求光宇游戏进行赔偿或补偿。</strong>
              </p>
              <p>
                <strong>您知悉并同意，您使用法定货币进行游戏充值购买或兑换游戏虚拟货币、虚拟道具及其他虚拟物品或增值服务的，视为您购买了游戏服务，您所购买或兑换的游戏虚拟货币、虚拟道具及其他虚拟物品或增值服务将不能退还或兑换成法定货币，但法律另有强制性规定的除外。</strong>
              </p>
              <p>您知悉并同意，如您进行游戏充值购买或兑换游戏虚拟货币的交易因退款等原因被取消的，光宇游戏有权就该被取消的交易对应下发到您游戏账号中的虚拟货币进行扣除。如您游戏账号中的虚拟货币不足以扣除的，就不足部分，光宇游戏可在您游戏账号中的虚拟货币后续增加时优先扣除。</p>
              <p>4.11
                在任何情况下，光宇游戏不对因不可抗力导致的您在使用光宇游戏服务过程中遭受的损失承担责任。该等不可抗力事件包括但不限于国家法律、法规、政策及国家机关的命令及其他政府行为或者其它的诸如地震、水灾、雪灾、火灾、海啸、台风、罢工、战争等不可预测、不可避免且不可克服的事件。</p>
              <p><strong>4.12
                光宇游戏可能因游戏软件BUG、版本更新缺陷、第三方病毒攻击或其他任何因素导致您的游戏角色、游戏道具、游戏装备及游戏币等账号数据或游戏数据发生异常。在数据异常的原因未得到查明前，光宇游戏有权暂时冻结该游戏账号；若查明数据异常为非正常游戏行为所致，光宇游戏有权恢复游戏账号数据至异常发生前的原始状态（包括向第三方追回被转移数据），而无须向您承担任何责任。若数据异常是由于您实施违法违规行为或违反本协议约定的行为所致，光宇游戏有权采取本协议第5.7条第二款规定的一种或多种处理措施。</strong>
              </p>
              <p><strong>4.13
                光宇游戏对除“奇宝斋”交易平台及游戏内合法交易的行为认可外的其它方式交易均不予承认，光宇游戏未授权您从除“奇宝斋”交易平台以及光宇游戏旗下所有游戏内合法交易平台之外任何第三方通过购买、接受赠与或者其他的方式获得游戏账号、游戏道具、游戏装备、游戏币及其他游戏服务。光宇游戏不对第三方交易行为负责，且光宇游戏有权采取本协议第5.7条第二款规定的一种或多种处理措施。</strong>
              </p>
              <p>
                <strong>由于虚拟物品与现实财产之间的交易存在巨大风险，请用户慎重对待以真实货币或者财物与游戏内虚拟货币或者物品之间进行的交易行为，除非您在此类交易行为进行的过程中得到我们书面的明确说明、指导和确认，我们不会对这一类交易中产生的任何问题（包括但不限于物品非复制性的确认、用户诚信的确认等）进行支持或者保障。</strong>
              </p>
              <p>4.14
                光宇游戏自行决定终止运营光宇游戏时或光宇游戏因其他任何原因终止运营时，光宇游戏会按照国家有关网络游戏终止运营的相关法律法规规定处理游戏终止运营相关事宜，以保障用户合法权益。不管由于任何原因终止运营，光宇游戏均会提前90日通知用户，用户应采取相应措施自行处理游戏帐号（包括注销或停止使用该帐号）、游戏内虚拟物品等相关事宜。用户不得以任何原因要求光宇游戏不得全面终止运营，也不得因全面终止运营而要求光宇游戏承担任何形式的赔偿或补偿责任（包括但不限于不再能继续使用游戏帐号、游戏内虚拟物品等）。</p>
            </li>
            <li>
              <h4><strong>五、【用户行为规范】</strong></h4>
              <p>5.1 您充分了解并同意，您必须为自己游戏账号下的一切行为负责，包括您所发表的任何内容以及由此产生的任何后果。</p>
              <p><strong>5.2
                您除了可以按照本协议的约定使用光宇游戏服务之外，不得进行任何侵犯光宇游戏的知识产权的行为，或者进行其他的有损于光宇游戏或其他第三方合法权益的行为，具体详细要求请以本条的后续规定为准。如您出现本条规定的违约或违规行为，光宇游戏有权依据5.7条约定根据其严重程度采取相应的处罚措施。</strong>
              </p>
              <p>5.3 您在使用光宇游戏或光宇游戏服务时须遵守法律法规，不得利用光宇游戏或光宇游戏服务从事违法违规行为，包括但不限于以下行为：</p>
              <ol>
                <li>（1）违反宪法确定的基本原则；</li>
                <li>（2）危害国家统一、主权和领土完整的；</li>
                <li>（3）泄露国家秘密、危害国家安全或者损害国家荣誉和利益的；</li>
                <li>（4）煽动民族仇恨、民族歧视，破坏民族团结，或者侵害民族风俗、习惯的；</li>
                <li>（5）宣扬邪教、迷信的；</li>
                <li>（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</li>
                <li>（7）宣扬淫秽、色情、赌博、暴力，或者教唆犯罪的；</li>
                <li>（8）侮辱、诽谤他人，侵害他人合法权益的；</li>
                <li>（9）违背社会公德的；</li>
                <li>（10）有法律、行政法规和国家规定禁止的其他内容的。</li>
              </ol>
              <p><strong>5.4 除非法律允许或光宇游戏书面许可，您不得从事下列行为：</strong></p>
              <ol>
                <li>（1）删除游戏软件及其副本上关于著作权的信息；</li>
                <li>（2）对游戏软件进行反向工程、反向汇编、反向编译或者以其他方式尝试发现软件的源代码或其他保密内容，包括但不限于光宇游戏暂未主动公开呈现但已封存在游戏安装包中的游戏元素等；</li>
                <li>（3）对游戏软件进行扫描、探查、测试，以检测、发现、查找其中可能存在的BUG或弱点；</li>
                <li>（4）对游戏软件或者软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经合法授权的第三方工具/服务接入软件和相关系统；</li>
                <li>（5）修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论上述行为是否为商业目的；</li>
                <li>（6）通过非光宇游戏开发、授权的第三方软件、插件、外挂、系统，使用光宇游戏及光宇游戏服务，或制作、发布、传播非光宇游戏开发、授权的第三方软件、插件、外挂、系统；</li>
                <li>（7）对游戏中光宇游戏拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；</li>
                <li>（8）建立有关光宇游戏的镜像站点，或者进行网页（络）快照，或者利用架设服务器等方式，为他人提供与光宇游戏服务完全相同或者类似的服务；</li>
                <li>（9）将光宇游戏的任意部分分离出来单独使用，或者进行其他的不符合本协议的使用；</li>
                <li>（10）使用、修改或遮盖光宇游戏的名称、商标或其它知识产权；</li>
                <li>（11）其他未经光宇游戏明示授权的行为。</li>
              </ol>
              <p><strong>5.5 禁止用户利用游戏进行与游戏无关的行为，包括但不限于：</strong></p>
              <ol>
                <li>
                  <strong>（1）为任何非法目的及与光宇游戏提供服务或产品未有直接关系而使用网络服务系统；包括但不限于买卖账号、角色、虚拟货币、虚拟道具、虚拟物品，宣传赌博、非法彩票等；</strong>
                </li>
                <li>
                  <strong>（2）利用游戏及光宇游戏提供的相关网络服务传输任何骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法的信息资料；</strong>
                </li>
                <li>
                  <strong>（3）不得在光宇游戏内进行违规喊话、违规操作，包括但不限于发送或传播非经光宇游戏认可的第三方网址、游戏交易平台、诱导其他玩家搜索第三方游戏平台、第三方交易信息、代练、代充值等信息；</strong>
                </li>
                <li><strong>（4）利用游戏及光宇游戏提供的相关网络服务进行任何不利于光宇游戏的行为。</strong></li>
              </ol>
              <p><strong>5.6
                不得采取任何破坏游戏规则和违反游戏公平性的行为，为避免破坏游戏的公平性或平衡性，用户同意并理解其只能通过光宇游戏进行正常的娱乐互动，以及基于该等娱乐互动的需要于光宇游戏提供或认可的交易平台上交易游戏虚拟物品。除上述情形之外的虚拟物品交易或其他任何牟利情形将被视为牟取不正当利益，包括但不限于用户：</strong>
              </p>
              <ol>
                <li><strong>（1）注册多个用户账号和/或游戏角色ID，以盈利为目的的进行游戏行为，使用光宇游戏产品和服务；</strong></li>
                <li><strong>（2）从事光宇游戏内单一或系列产出玩法，将获得的虚拟物品出售获利；</strong></li>
                <li><strong>（3）利用不同服务器的虚拟物品价值差异，在不同服务器买卖虚拟物品获利；</strong></li>
                <li><strong>（4）充当游戏账号、虚拟物品交易中介收取费用获利；</strong></li>
                <li><strong>（5）在非光宇游戏提供或认可的交易平台上交易用户账号或虚拟物品获利；</strong></li>
                <li><strong>（6）将光宇游戏内获得的虚拟物品用于出售获利而不注重本身角色实力的提升；</strong></li>
                <li><strong>（7）利用光宇游戏行为和游戏内容组织或参与赌博、实施或参与实施盗窃他人财产或虚拟物品等涉嫌违法犯罪行为的；</strong></li>
                <li><strong>（8）利用光宇游戏系统可能存在的技术缺陷或漏洞而以各种形式为自己及他人牟利（包括但不限于复制游戏中的虚拟物品等）。</strong></li>
                <li>
                  <strong>（9）利用光宇游戏侵害第三人权益，如因用户违反有关法律、法规或本协议项下的任何条款而给任何其他第三人造成损失，包括但不限于利用光宇游戏进行诈骗或利用光宇游戏进行销赃的；</strong>
                </li>
                <li><strong>（10）与第三方进行线下交易，故意规避交易平台的相关管理规则及监管；</strong></li>
                <li><strong>（11）其他任何不以正常的游戏娱乐需要为目的的游戏内牟利行为。</strong></li>
              </ol>
              <p><strong>5.7
                您在使用光宇游戏服务过程中如有以下任何行为（以下也称“违法违规行为”）之一的，光宇游戏有权视情节严重程度，依据本协议及相关游戏规则的规定，对您采取本条第二款规定的一种或多种处理措施，并可公告处理结果：</strong>
              </p>
              <ol>
                <li><strong>（1）以某种方式暗示或伪称光宇游戏内部员工或某种特殊身份，企图得到不正当利益或影响其他用户权益的行为；</strong></li>
                <li><strong>（2）在光宇游戏中使用、上传违法或不当词语、字符及其他内容的行为，包括用于角色命名；</strong></li>
                <li><strong>（3）以任何方式破坏光宇游戏或影响光宇游戏服务的正常进行，包括但不限于：</strong></li>
                <li>
                  <ol className="ol01">
                    <li>
                      <strong> 1）违规及（或）恶意注册、登录游戏的行为，包括但不限于恶意批量注册游戏账号，以及以破坏服务器鉴权、恶意挤服等方式登录游戏行为；</strong>
                    </li>
                    <li>
                      <strong> 2）恶意实施可能导致游戏服务器宕机、卡顿，或导致其他游戏玩家掉线、延迟等无法正常游戏的行为，如利用DDOS对游戏服务器或其他玩家进行网络流量攻击的行为等；</strong>
                    </li>
                    <li><strong> 3）利用游戏漏洞实施获益、破坏游戏环境或给其他玩家造成不良影响的行为；</strong></li>
                    <li><strong> 4）游戏数据表现异常，影响光宇游戏公平的其他行为；</strong></li>
                  </ol>
                </li>
                <li><strong>（4）使用各种私服、外挂行为；</strong></li>
                <li>
                  <strong>（5）使用游戏同步器（具体包括但不限于使用键盘、鼠标等硬件同步器，以便可以使用一套键盘、鼠标，同时、同步控制多台电脑进行游戏的行为），以及使用其他各种可以让您在游戏效率或收益数据上表现异常的硬件（包括但不限于“连点器”或“按键精灵”等，以下统称“作弊硬件”）；</strong>
                </li>
                <li><strong>（6）传播非法言论或不当信息；</strong></li>
                <li><strong>（7）盗取他人游戏账号、游戏物品；</strong></li>
                <li><strong>（8）进行游戏账号交易、账号共享等影响账号安全的行为；</strong></li>
                <li>
                  <strong>（9）未经光宇游戏许可，擅自与其他用户进行游戏虚拟货币、游戏装备、游戏币及其他游戏道具等交易，或规避官方交易平台从第三方通过购买、接受赠与或者其他式获得游戏虚拟货币、游戏装备、游戏币及其他游戏道具或游戏服务。</strong>
                </li>
                <li>
                  <strong>（10）在未经光宇游戏授权或认可的折扣渠道进行游戏充值、进行折扣游戏道具交易（例如在非光宇游戏官方渠道低价购买折扣金元宝）或委托未获光宇游戏授权或认可的第三方代为充值，或者使用未经光宇游戏授权或认可的第三方软件充值。</strong>
                </li>
                <li><strong>（11）违反本协议任何约定，或违反国家法律法规任何规定的行为；</strong></li>
                <li><strong>（12）其他在行业内被广泛认可的不当行为。</strong></li>
              </ol>
              <p><strong>前款规定的处理措施包括但不限于：</strong></p>
              <ol>
                <li><strong>（1）警告；及/或</strong></li>
                <li><strong>（2）禁言，包括但不限于游戏全区禁言、单区禁言以及公共频道禁言；及/或</strong></li>
                <li><strong>（3）内容重置，包括但不限于重置或强制修改您提供或上传的非法昵称、房间名、图片等非法信息或内容等；及/或</strong></li>
                <li>
                  <strong>（4）减少或限制您的游戏收益，该游戏收益包括但不限于游戏中与游戏角色成长升级相关的所有数据（如经验值、荣誉值、道行称号等）的提升以及游戏中的道具、装备、虚拟货币等虚拟物品的获取；及/或</strong>
                </li>
                <li><strong>（5）删除或注销游戏账号、游戏数据或其他游戏相关信息；及/或</strong></li>
                <li><strong>（6）限制或禁止您进入游戏中的特定场景或使用游戏特定功能；及/或</strong></li>
                <li><strong>（7）强制退出游戏（即“踢出游戏”），包括但不限于强制退出整个游戏，或游戏中的单局对战，或您正在进行中的其他游戏行为；及/或</strong>
                </li>
                <li><strong>（8）暂时或永久性地禁止您游戏账号登录光宇游戏（以下称“封号”）；及/或</strong></li>
                <li><strong>（9）暂时或永久性地禁止您违法违规行为涉及的特定终端（包括但不限于PC终端、移动终端等）登录光宇游戏；及/或</strong></li>
                <li><strong>（10）暂停或终止您使用光宇游戏及相关服务，或单方通知您终止本协议；及/或</strong></li>
                <li>
                  <strong>（11）就您的违法违规行为提起相应民事诉讼，追究您的侵权、违约或其他民事责任，并要求您赔偿光宇游戏公司因您违法违规行为所受到的损失（包括光宇游戏公司所受到的直接经济损失、名誉或商誉损失以及光宇游戏公司对外支付的赔偿金、和解费用、律师费用、诉讼费用及其他间接损失），或移交有关行政管理机关给予行政处罚，或者移交司法机关追究您的刑事责任；及/或</strong>
                </li>
                <li><strong>（12）光宇游戏通过游戏规则（包括但不限于用户守则、玩家条例、游戏公告、提示及通知）公布的其他处理措施。</strong></li>
                <li>
                  <strong>您知悉并同意，由于外挂及游戏同步器等作弊硬件具有隐蔽性或用完后即消失等特点，光宇游戏有权根据您的游戏数据和表现异常判断您有无使用非法外挂或游戏同步器等作弊硬件行为。</strong>
                </li>
              </ol>
              <p><strong>5.8 您知悉并同意，如光宇游戏依据本协议对您的游戏账号采取封号处理措施的，具体封号期间由光宇游戏根据您违规行为情节而定。</strong></p>
              <p>
                <strong>您知悉并同意：（1）在封号期间，您游戏账号中的游戏虚拟货币、游戏装备、游戏币及其他游戏道具可能都将无法使用；（2）如前述游戏虚拟货币、游戏装备、游戏币及其他游戏道具存在一定有效期，该有效期可能会在封号期间过期，您游戏账号解封后，您将无法使用该等已过期的游戏虚拟货币、游戏装备、游戏币及其他游戏道具。（3）如前述游戏虚拟货币、游戏装备、游戏币及其他游戏道具在封号期间发生物品游戏内流通价值贬损，光宇游戏不承担相应责任。（4）如因用户违反本协议的规定导致其注册帐号被删除的，用户无权要求光宇游戏返还其帐号中剩余点数对应的人民币价值。</strong>
              </p>
            </li>
            <li>
              <h4><strong>六、【知识产权】</strong></h4>
              <p>6.1
                光宇游戏是光宇游戏的知识产权权利人。光宇游戏（包括但不限于光宇游戏整体、游戏内所包含的所有美术、音乐、文字作品和其他游戏构成要素、组成部分，以及光宇游戏运行呈现的连续动态画面）的一切著作权、商标权、专利权、商业秘密等知识产权及其他合法权益，以及与光宇游戏相关的所有信息内容（包括文字、图片、音频、视频、图表、界面设计、版面框架、游戏数据及数值体系、游戏过程衍生品、游戏编辑衍生品等有关数据或电子文档等）均受中华人民共和国法律法规和相应的国际条约保护，光宇游戏享有上述知识产权和合法权益，但相关权利人依照法律规定应享有的权利除外。未经光宇游戏事先书面同意，您不得以任何方式将光宇游戏（包括但不限于光宇游戏整体、游戏内所包含的所有美术、音乐、文字作品和其他游戏构成要素、组成部分，以及光宇游戏运行呈现的连续动态画面
                ）进行商业性使用或通过信息网络传播光宇游戏内容。</p>
              <p>6.2
                如光宇游戏存在可供您自行制作、创作或上传相关内容的平台功能或服务的（以下称”UGC平台”），针对您使用UGC平台制作、创作、上传或发布的内容（以下称“UGC内容”），您应确保其合法且不侵犯他人合法权益并应遵守光宇游戏在UGC平台上发布的相关规则（以下称“UGC平台规则”），且您同意：</p>
              <ol>
                <li>（1）如UGC内容含有光宇游戏内容（包括但不限于光宇游戏整体、游戏内所包含的所有美术、音乐、文字作品和其他游戏构成要素、组成部分，以及光宇游戏运行呈现的连续动态画面）及（或）光宇游戏公司享有知识产权的其他内容的，则您对UGC内容进行商业目的使用需要经光宇游戏公司书面授权同意，并遵守相应的UGC平台规则。</li>
                <li>（2）针对UGC内容，
                  您同意不可撤销地授权光宇游戏以任何方式使用该UGC内容（前述“使用”包括但不限于复制、发行、出租、展览、表演、广播、修改、改编、信息网络传播及其他方式使用，同时包括商业目的和非商业目的使用，本游戏内和本游戏外使用，也包括在UGC平台内和UGC平台外使用。为方便描述，以下将前述使用的权利统称“使用权”），同时授权光宇游戏可以将前述使用权转授权或分许可第三方使用。您承诺光宇游戏的该等使用不会侵害任何第三方知识产权及其他合法权益。
                </li>
                <li>（3）针对UGC内容，您仅可在光宇游戏及光宇游戏认可的其他平台对该UGC内容作出展示或按光宇游戏认可方式使用，不得在未经光宇游戏许可情况下擅自或授权第三方在其他平台进行传播，也不得擅自或授权第三方进行任何出版、商业盈利、二次衍生及其他使用的行为。如违反，光宇游戏将保留法律上的追诉权利。</li>
                <li>（4）针对UGC内容，您不可撤销地授权光宇游戏以自己的名义或委托专业第三方对侵犯UGC内容合法权益的行为进行维权，维权形式包括但不限于：监测侵权行为、发送维权函、提起诉讼或仲裁、调解、和解等，光宇游戏有权对维权事宜做出决策并独立实施。</li>
                <li>（5）如根据著作权法等法律规定，您对UGC内容享有发表权、署名权、修改权和保护作品完整权等著作人身权的，您同意光宇游戏可自行根据UGC的具体使用情况或使用场景是否为您进行署名以及以什么方式进行署名，且您不会因光宇游戏按本协议约定使用UGC内容而向光宇游戏主张发表权、署名权、修改权和保护作品完整权等著作人身权。</li>
              </ol>
              <p><strong>6.3
                光宇游戏产品和服务中的各种虚拟物品，包括但不限于元宝等虚拟货币或游戏币、道具、装备等，是光宇游戏所提供的服务的一部分，其归属于光宇游戏的软件产品内，并不构成独立的虚拟财产。用户只能在合乎法律规定和游戏规则的情况下使用，且可通过游戏行为和充值行为获准使用部分虚拟物品，但不应视为用户获得了该等虚拟物品对应的财产权。本协议所称玩家对“虚拟物品的交换/转让”均指对应虚拟物品的服务的交换/转让，玩家无权以虚拟财产的方式交换/转让虚拟物品的所有权。</strong>
              </p>
              <p>您知悉并同意：用户向其拥有的光宇游戏相关产品和服务的账号内充值兑换获取虚拟物品时，光宇游戏可能会同时赠与一定比例的虚拟物品，用户在使用及消耗虚拟物品时，如充值兑换虚拟物品与获赠的虚拟物品不可区分，充值兑换的虚拟物品将视为优先于光宇游戏赠予的虚拟物品被使用与消耗，如充值兑换的虚拟物品与获赠的虚拟物品可以区分，则按照实际使用及消耗量计算。</p>
              <p><strong>6.4 尽管本协议有其他规定，您在使用光宇游戏服务中产生的游戏数据的所有权和知识产权归光宇游戏所有，光宇游戏有权保存、处置该游戏数据。</strong>其中，光宇游戏对用户购买游戏虚拟货币的购买记录的保存期限将遵守国家法律法规有关规定。对其他游戏数据的保存期限由光宇游戏自行决定，但国家法律法规另有规定的从其规定。
              </p>
              <p>6.5 光宇游戏可能涉及第三方知识产权，而该等第三方对您基于本协议在光宇游戏中使用该等知识产权有要求的，光宇游戏将以适当方式向您告知该要求，您应当一并遵守。</p>
            </li>
            <li>
              <h4><strong>七、【遵守当地法律监管】</strong></h4>
              <p>7.1 您在使用光宇游戏服务过程中应当遵守当地相关的法律法规，并尊重当地的道德和风俗习惯。如果您的行为违反了当地法律法规或道德风俗，您应当为此独立承担责任。</p>
              <p>7.2 您应避免因使用光宇游戏服务而使光宇游戏卷入政治和公共事件，否则光宇游戏有权暂停或终止对您的服务。</p>
              <p><strong>7.3
                如您在游戏内违规行为给光宇游戏或第三方造成损害的，您应承担相应的法律责任，而且针对自身的损害，光宇游戏有权采取一切合理的行动，有权向您要求承担光宇游戏合理的追索费用（如诉讼费、律师费、公证费等）。</strong>
              </p>
            </li>
            <li>
              <h4><strong>八、【管辖与法律适用】</strong></h4>
              <p><strong> 8.1 本协议签订地为中华人民共和国北京市海淀区。</strong></p>
              <p>8.2 本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。</p>
              <p>8.3 若您和光宇游戏之间因本协议发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交至本协议签订地有管辖权的人民法院管辖。</p>
              <p>8.4 本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。</p>
              <p>8.5 本协议条款无论因何种原因部分无效，其余条款仍有效，对各方具有约束力。</p>
            </li>
            <li>
              <h4><strong>九、【条款变更】</strong></h4>
              <p><strong>光宇游戏有权在必要的时候修改本协议条款，敬请用户定期查询有关内容。用户如继续使用本协议涉及的服务，则视为对修改内容的同意；</strong></p>
              <p>用户在不同意修改内容的情况下，应当卸载光宇游戏软件、终止本协议并停止使用光宇游戏服务。</p>
            </li>
            <li>
              <h4><strong>十、【通知】</strong></h4>
              <p>
                <strong>光宇游戏所有发给用户的通知可通过游戏内、网站或论坛重要页面的公告、电子邮件或常规的电话、短信、信件等形式传送。该等信息一经光宇游戏采取前述任何一种方式公布或发送，即视为送达，</strong>对用户产生约束力。若您不接受的，请您书面通知光宇游戏，否则视为您已经接受、同意。同时，用户在此同意光宇游戏可以向其电子邮箱、电话、短信等发送商业信息。
              </p>
            </li>
            <li>
              <h4><strong>十一、【免责条款】</strong></h4>
              <p><strong>11.1
                光宇游戏不就因用户使用光宇游戏的产品和服务引起的，或在任何方面与光宇游戏的产品和服务有关的任何意外的、非直接的、特殊的、或间接的损害或请求（包括但不限于因人身伤害、因隐私泄漏、因未能履行包括诚信或合理谨慎在内的任何责任、因过失和因任何其他金钱上的损失或其他损失而造成的损害赔偿、不正当使用产品服务、在网上购买商品或类似服务、在网上进行交易、非法使用服务或用户传送的信息有所变动）承担任何责任。这些损害会导致光宇游戏形象受损，所以光宇游戏早已提出这种损害的可能性。</strong>
              </p>
              <p><strong>11.2
                光宇游戏对本项服务下涉及的境内外基础电信运营商的移动通信网络的故障、技术缺陷、覆盖范围限制、不可抗力、计算机病毒、黑客攻击、用户所在位置、用户关机或其他非光宇游戏技术能力范围内的事因等造成的服务中断、用户发送的短信息的内容丢失、出现乱码、错误接收、无法接收、迟延接收不承担责任。</strong>
              </p>
              <p>11.3
                您充分理解到，包括但不限于以下情况：因网络机房方面的故障导致的数据丢失（包括程序Bug导致的数据异常）时，或者为有效遏制和处理用户使用BUG非法获利的行为，光宇游戏公司有可能会采取回档处理，将通行证上的社区信息、游戏信息还原到一定时点，对此，您充分理解并愿意自行承担，光宇游戏公司将不承担任何责任。</p>
              <p><strong>11.4 在发生下列情形之一时，即使未经通知，光宇游戏有权中断所提供的网络服务，并不需要向用户承担任何责任：</strong></p>
              <ol>
                <li>（1）定期对有关的网站服务器或游戏服务器及其他网络设备或所提供的产品和网络服务的相关官方网站进行必要的维护、保养及施工；用户可以到光宇游戏官方网站和游戏公告中查询每日停机维护时间；</li>
                <li>（2）因光宇游戏、光宇游戏的合作方或电信网络系统软硬件设备的故障、失灵或人为操作的疏失；</li>
                <li>（3）他人侵入光宇游戏的网络，篡改、删改或伪造、编造网站数据，或实施了任何影响光宇游戏计算机系统正常运行的行为；</li>
                <li>（4）不可抗力原因；</li>
                <li>（5）由于相关机构基于法律或法定程序的要求；</li>
                <li>（6）其他基于法律或国家政策的规定。</li>
              </ol>
            </li>
            <li>
              <h4><strong>十二、【其他】</strong></h4>
              <p>12.1
                光宇游戏有权在必要时变更本协议条款，您可以在光宇游戏的相关页面查阅最新版本的协议条款。<strong>本协议条款变更后，如果您继续使用光宇游戏服务，即视为您已接受变更后的协议。</strong>
              </p>
              <p>12.2 光宇游戏提醒您：抵制不良游戏，拒绝盗版游戏；注意自我保护，谨防受骗上当；适度游戏益脑，沉迷游戏伤身；合理安排时间，享受健康生活。</p>
            </li>
          </ul>
        </div>
        &nbsp;<br/>
        <div className="info">
          <h2><a id="Privacy"></a>光宇保护隐私条款</h2>
          尊重用户个人隐私是光宇游戏的一项基本政策。我们将通过《光宇游戏保护隐私条款》向您进一步细化说明您在使用光宇游戏时我们收集、使用、存储和共享个人信息的情况，以及您所享有的相关权利的事宜：
          <ul>
            <li>
              <h4>1.我们收集的信息</h4>
              <p>
                在您使用光宇游戏服务的过程中，光宇游戏会按照如下方式收集您在使用服务时主动提供的或因为使用服务而产生的信息，用以向您提供、优化我们的服务以及保障您的账户安全：
              </p>

              <p>1.1 当您注册或使用光宇游戏服务时，我们会收集您的网络身份标识信息及个人常用设备信息，用于标记您为光宇游戏的用户。</p>
              <p>1.2
                为满足相关法律法规政策及相关主管部门的要求，光宇游戏用户需进行实名认证以继续使用和享受光宇游戏。我们会在获得您同意或您主动提供的情况下收集您的实名身份信息（包括姓名、身份证号、照片等信息），该信息属于敏感信息，拒绝提供实名身份信息可能会导致您无法登录光宇游戏或在使用光宇游戏过程中受到相应限制。</p>
              <p>1.3
                为保障您正常使用我们的服务，维护游戏基础功能的正常运行，优化游戏产品性能，提升您的游戏体验并保障您的账号安全，我们会收集您的设备ID、设备名称、设备类型和版本、系统版本、IP地址、设备识别符（如
                IMEI、IMSI、MAC地址）、应用ID、网络类型等信息。</p>
              <p>1.4
                当您使用光宇游戏服务时，我们会收集您的游戏日志信息，例如登录日志、物品日志、游戏角色信息、交友记录等，以便您能够在客户端查看您的游戏历史记录，同时用于游戏运营统计分析、客服投诉处理及其他游戏安全分析，并为提升您的游戏体验，我们可能把前述信息同步至该游戏后续版本或您正在使用的我们提供的其他产品中。</p>
              <p>1.5
                当您使用光宇游戏产品的消费功能时，我们会收集您的充值记录、消费记录等信息，以便您查询您的交易记录，同时尽最大程度保护您的虚拟物品安全。充值记录、消费记录属于敏感信息，收集上述信息为实现光宇游戏产品的消费功能所必须，否则将无法完成交易。</p>
              <p>1.6
                为保障您的游戏账号安全，营造公平、健康及安全的游戏环境，我们会收集您的游戏识别信息、硬件及操作系统信息、应用列表、进程及游戏崩溃记录等信息，以用于打击破坏游戏公平环境或干扰、破坏游戏服务正常进行的行为（如用于检测盗版、扫描外挂、防止作弊等）。</p>
              <p>1.7
                当您在游戏中通过文字、图片、语音、视频及其他方式与其他玩家进行互动，我们可能会收集并保存您发送的上述信息内容用于过滤色情、暴力、政治、辱骂、恶意广告等不当内容，以此净化游戏环境，维护健康的上网环境。</p>
              <p>1.8
                如您希望通过图片、语音、视频与其他游戏玩家互动、参与直播，在您授权同意后，游戏会访问您的照片或存储文件、麦克风、摄像头，为您提供截图保存、图片上传、语音聊天、直播互动等功能。</p>
              <p>1.9 为保障游戏安全稳定运行并实现特定功能，部分游戏可能会接入第三方开发的软件工具开发包（Software Development
                Kit，SDK）。我们会对游戏接入的涉及个人信息收集的SDK进行安全监测，以保护您的个人信息安全。</p>
              <p>1.10
                我们可能会将您的游戏数据进行分析以了解您的偏好，并可能将您的偏好等相关必要的信息分享给我们的其他产品或服务，以便于我们更好地在其他产品或服务中向您展示可能感兴趣的内容。如某产品或服务涉及以上内容展示，您可以通过该等产品或服务对展示内容进行管理，详情请见产品内说明。我们可能通过Cookie、标识符及相关技术收集您的信息，为您提供更好的用户体验、保障服务安全。</p>
              <p>1.11 根据相关法律法规及国家标准，以下情形中，我们可能会收集、使用您的相关个人信息无需征求您的授权同意：</p>
              <p>1） 与国家安全、国防安全等国家利益直接相关的；与公共安全、公共卫生、公共知情等重大公共利益直接相关的；</p>
              <p>2） 与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
              <p>3） 出于维护您或其他个人的生命、财产、声誉等重大合法权益但又很难得到本人同意的；</p>
              <p>4） 所收集的个人信息是您自行向社会公众公开的；</p>
              <p>5） 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
              <p>6） 根据您要求签订和履行合同所必需的；</p>
              <p>7） 用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</p>
              <p>8） 为开展合法的新闻报道所必需的；</p>
              <p>9） 出于公共利益开展统计或学术研究所必要，且其对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</p>
              <p>10） 法律法规规定的其他情形。</p>
              <p>1.12
                请您理解，我们向您提供的功能和服务是不断更新和发展的，如果某一功能或服务未在前述说明中且收集了您的信息，我们会通过页面提示、交互流程、网站公告等方式另行向您说明信息收集的内容、范围和目的，以征得您的同意。</p>


            </li>
            <li>
              <h4>2.信息的存储</h4>

              <p>2.1 信息存储的方式和期限</p>
              <p>我们会通过安全的方式存储您的信息，包括本地存储（例如利用APP进行数据缓存）、数据库和服务器日志。</p>
              <p>一般情况下，我们只会在为实现服务目的所必需的时间内或法律法规规定的条件下存储您的个人信息。</p>
              <p>2.2 信息存储的地域</p>
              <p>我们会按照法律法规规定，将境内收集的用户个人信息存储于中国境内。</p>
              <p>2.3 产品或服务停止运营时的通知</p>
              <p>当我们的产品或服务发生停止运营的情况时，我们将根据相关法律法规规定进行公告通知，并依法保障您的合法权益。</p>


            </li>
            <li>
              <h4>3.信息安全</h4>
              <p>3.1 安全保护措施</p>
              <p>我们努力为用户的信息安全提供保障，以防止信息的泄露、丢失、不当使用、未经授权访问和披露等。我们使用多方位的安全保护措施，以确保用户的个人信息保护处于合理的安全水平，包括技术保护手段、管理制度控制、安全体系保障等诸多方面。</p>
              <p>我们采用业界领先的技术保护措施。我们使用的技术手段包括但不限于防火墙、加密（例如SSL）、去标识化或匿名化处理、访问控制措施等。此外，我们还会不断加强安装在您设备端的软件的安全能力。例如，我们会在您的设备本地完成部分信息加密工作，以巩固安全传输；我们会了解您设备安装的应用信息和运行的进程信息，以预防病毒、木马等恶意程序。</p>
              <p>我们建立了保障个人信息安全专门的管理制度、流程和组织。例如，我们严格限制访问信息的人员范围，要求他们遵守保密义务并进行审计，违反义务的人员会根据规定进行处罚。我们也会审查该管理制度、流程和组织，以防未经授权的人员擅自访问、使用或披露用户的信息。</p>
              <p>3.2 安全事件处置措施</p>
              <p>若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大。安全事件发生后，我们会以公告、推送通知或邮件等形式告知您安全事件的基本情况、我们即将或已经采取的处置措施和补救措施，以及我们对您的应对建议。如果难以实现逐一告知，我们将通过公告等方式发布警示。</p>

            </li>
            <li>
              <h4>4.我们如何使用信息</h4>
              <p>我们严格遵守法律法规的规定以及与用户的约定，按照《光宇游戏保护隐私条款》所述使用收集的信息，以向您提供更为优质的服务。</p>
              <p>4.1 信息使用规则</p>
              <p>我们会按照如下规则使用收集的信息：</p>
              <p>1) 我们会根据我们收集的信息向您提供各项功能与服务，包括基础游戏功能、玩家互动功能、消费功能等；</p>
              <p>2)
                我们会根据您使用光宇游戏产品的频率和情况、故障信息、性能信息等分析我们产品的运行情况，以确保服务的安全性，并优化我们的产品，提高我们的服务质量。我们不会将我们存储在分析软件中的信息与您提供的个人身份信息相结合。</p>
              <p>4.2 告知变动目的后征得同意的方式</p>
              <p>我们将会在本指引所涵盖的用途内使用收集的信息。如我们使用您的个人信息，超出了与收集时所声称的目的及具有直接或合理关联的范围，我们将在使用您的个人信息前，再次向您告知并征得您的明示同意。</p>


            </li>
            <li>
              <h4>5.对外提供</h4>
              <p>5.1
                除本指引以及《光宇通行证用户协议》规定的情形之外，我们不会主动共享、提供或转让您的个人信息至第三方，如存在其他共享、提供或转让您的个人信息或您需要我们将您的个人信息共享、提供或转让至第三方情形时，我们会直接或间接通过该第三方征得您对上述行为的明示同意。</p>
              <p>为提升您的用户体验，例如优化广告效果（您可以在关于广告页面中了解和管理为您展示的广告），我们可能需要向第三方合作伙伴等，分享已经匿名化或去标识化处理后的信息，要求其严格遵守我们关于数据隐私保护的措施与要求，包括但不限于根据数据保护协议、承诺书及相关数据处理政策进行处理，避免识别出个人身份，保障隐私安全。</p>
              <p>我们不会向合作伙伴分享可用于识别您个人身份的信息（例如您的姓名或电子邮件地址），除非您明确授权。</p>
              <p>5.2
                除非另有规定外，我们不会对外公开披露所收集的个人信息。如必须公开披露时，我们会向您告知此次公开披露的目的、披露信息的类型及可能涉及的敏感信息，并征得您的明示同意。</p>
              <p>5.3
                随着我们业务的持续发展，我们有可能进行合并、收购、资产转让等交易，我们将告知您相关情形，按照法律法规及不低于本指引所要求的标准继续保护或要求新的控制者继续保护您的个人信息。</p>
              <p>5.4 根据相关法律法规及国家标准，以下情形中，我们可能会共享、转让、公开披露个人信息无需事先征得您的授权同意：</p>
              <p>1） 与国家安全、国防安全直接相关的；</p>
              <p>2） 与公共安全、公共卫生、重大公共利益直接相关的；</p>
              <p>3） 与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
              <p>4） 出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
              <p>5） 个人信息主体自行向社会公众公开个人信息的；</p>
              <p>6） 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</p>

            </li>
            <li>
              <h4>6.您的权利</h4>
              <p>在您使用光宇游戏服务期间，我们可能会视游戏产品具体情况为您提供相应的操作设置，以便您可以查询、删除、更正或撤回您的相关个人信息，您可参考相应游戏产品的具体指引进行操作。此外，我们还设置了投诉举报渠道，您的意见将会得到及时的处理。</p>

            </li>
            <li>
              <h4>7.联系我们</h4>
              <p>当您有其他的投诉、建议等相关问题请随时通过客服与我们联系。</p>
            </li>
            <li>
              <h4>8.其他</h4>
              <p>
                <strong>本保护隐私条款为《光宇通行证服务协议》条款的补充，若有任何与前述协议冲突的地方，以前述协议为准。当本条款发生变更时，我们会在版本更新时以适当的方式向您提示变更后的指引。请您仔细阅读变更后的隐私保护指引或指引内容，您继续使用光宇游戏表示您同意我们按照更新后的隐私保护指引收集、处理或使用您的个人信息。</strong>
              </p>

            </li>
            <li></li>
          </ul>
          {/* <strong>本保护隐私条款为《光宇通行证服务协议》条款的补充，若有任何与前述协议冲突的地方，以前述协议为准。</strong><br />
				<strong>《光宇保护隐私条款》的修改权、解释权和更新权均属于北京光宇在线科技有限责任公司。</strong> */}
        </div>
      </div>

    </div>
  )
}
