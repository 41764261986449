import React from 'react';
import { Link } from 'react-router-dom';

export default () => {
  return (
    <div className="headerbj head_login">
      <div className="top-Nav">
        <ul className="main_nav clear">
          <li className="main_nav_on1"><Link className="main_nav_01" to="/">首 页</Link></li>
          <li><a className="main_nav_02" target="_blank" href="//account.gyyx.cn/">账号管理</a></li>
          <li><a className="main_nav_03" target="_blank" href="//pay.gyyx.cn/">充值中心</a></li>
          <li><a className="main_nav_04" target="_blank" href="//change.gyyx.cn/">兑换中心</a></li>
          <li><a className="main_nav_05" target="_blank" href="//security.gyyx.cn/">安全中心</a></li>
          {/*<li><a className="main_nav_06" href="http://extend.gyyx.cn/">推广中心</a></li>*/}
          <li><a className="main_nav_07" href="//faq.gyyx.cn" target="_blank" rel="noreferrer"
                 statistics="true">客服中心</a></li>
        </ul>
      </div>
    </div>)
}