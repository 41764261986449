/*
 * @Author: liujunhong
 * @Date: 2022/12/24
 * @Desc: 
 */
import React, { useEffect, useState } from "react"
import Form, { Field as FormItem } from "../../components/CustomFormControl/Form"
import {
  ACCOUNT_RULES,
  BID_REGISTER_GAME,
  CER_NUM_RULES, CHECK_CODE_RULES, E_MAIL_RULES,
  ENTRY_PER, MOBILE_RULES,
  PW_RULES, REGEX_MOBILE,
  REGEX_PW, SOURCE_TYPE_PER,
  TRUE_NAME_RULES, USE_TYPE
} from "../../consts/register"
import { RegisterPersonal } from "account-net-sdk"
import Input from "../../components/CustomFormControl/Input"
import { Captcha } from "gyyx-component-lib"
import SendMsg from '../../components/CustomFormControl/SendMsg'
import './captcha.scss'


const Personality = (props) => {
  const { mobileVisible, cnt, isAgree, onSuccess } = props
  const [form] = Form.useForm()
  const [captchaVisible, setCaptchaVisible] = useState(false)
  const [validCbData, setValidCbData] = useState({})
  const [errorTips, setErrorTips] = useState('')
  const [formItem, setFormItem] = useState({})


  const handleSubmit = () => {
    const error = form.validate()
    if (Object.keys(error).length) {
      setFormItem({ ...error })
      return
    }
    if (!isAgree) {
      setErrorTips('请勾选并阅读协议')
      return
    }

    if (!mobileVisible && !Object.keys(validCbData).length) {
      setCaptchaVisible(true)
      return
    }

    if (mobileVisible) {
      const { phone, checkCode } = form.getFieldsValue()
      requestSubmit({ phone, checkCode })
    }
  }

  const requestSubmit = (data) => {

    const { account, password, passwordCompare, trueName, email, cerNumber } = form.getFieldsValue()

    let params = {
      isNeedPhone: mobileVisible,
      account,
      password,
      passwordCompare,
      trueName,
      cerNumber,
      email,
      bid: BID_REGISTER_GAME,
      regEntry: ENTRY_PER,
      ...data
    }

    RegisterPersonal(params, USE_TYPE).then(res => {
      console.log(res)
      setValidCbData({})
      if (!res.data.success) {
        setErrorTips(res.data.message)
        return

      }
      onSuccess()

    })
  }


  const handleCaptchaSubmit = (data) => {
    const { captcha, cookies, balanceCode } = data
    setValidCbData({ ...data })
    setCaptchaVisible(false)
    requestSubmit({ captcha, cookies, balanceCode })
  }

  const handleValidClose = () => {
    setCaptchaVisible(false)
  }


  useEffect(() => {
    console.log(cnt)
    if (!cnt) {
      return
    }

    handleSubmit()

    console.log('useEffect-----', isAgree)

  }, [cnt])

  const handleBlur = (key) => {
    let validMap = form.validate(key)
    console.log(validMap)
    if (!Object.keys(validMap).length) {
      delete formItem[key]
      setFormItem({ ...formItem })
      return
    }
    setFormItem({ ...formItem, ...validMap })
  }
  const handleFocus = (key, msg) => {
    setFormItem({ ...formItem, [key]: msg })
  }

  const onError = (res) => {
    setFormItem({ ...formItem, 'checkCode': res.message })
  }

  return <>
    {<p className='client_register_tips'>{errorTips}</p>}
    <Form form={form}>
      <FormItem name="account" label='通行证账号：' rules={ACCOUNT_RULES}>
        <Input onBlur={() => handleBlur('account')}
          onFocus={() => handleFocus('account', '以字母开始的6-16位半角字符')}
          msg={formItem?.account} id='input_register_personality_account' msgId='text_register_personality_account' />
      </FormItem>
      <FormItem name="password" label='密码：' rules={PW_RULES}>
        <Input type='password' onBlur={() => handleBlur('password')}
          onFocus={() => handleFocus('password', '6-16位字符，(除<和>以及空格)')} msg={formItem?.password}
          id='input_register_personality_password' msgId='text_register_personality_password' />
      </FormItem>
      <FormItem name="passwordCompare" label='确认密码：' rules={[
        { rule: "require", message: '此项为必填项' },
        { rule: `regexp:${REGEX_PW}`, message: '6-16位字符，(除<和>以及空格)' },
        {
          rule: () => {
            return form.getFieldValue('password') === form.getFieldValue('passwordCompare')
          }, message: '两次输入的密码不一致'
        },
      ]}>
        <Input type='password' onBlur={() => handleBlur('passwordCompare')}
          msg={formItem?.passwordCompare} id='input_register_personality_passwordCompare' msgId='text_register_personality_passwordCompare' />
      </FormItem>
      <FormItem name="trueName" label='真实姓名：' rules={TRUE_NAME_RULES}>
        <Input onBlur={() => handleBlur('trueName')}
          onFocus={() => handleFocus('trueName', '真实姓名由汉字组成')} msg={formItem?.trueName}
          id='input_register_personality_trueName' maxLength={30} msgId='text_register_personality_trueName' />
      </FormItem>
      <FormItem name="cerNumber" label='身份证：' rules={CER_NUM_RULES}>
        <Input onBlur={() => handleBlur('cerNumber')}
          onFocus={() => handleFocus('cerNumber', '证件号不能修改，请谨慎填写')} msg={formItem?.cerNumber}
          id='input_register_personality_cerNumber' msgId='text_register_personality_cerNumber' />
      </FormItem>
      <FormItem name="email" label='电子邮箱：' rules={E_MAIL_RULES}>
        <Input onBlur={() => handleBlur('email')}
          onFocus={() => handleFocus('email', `官方推荐使用'QQ邮箱'`)} msg={formItem?.email}
          id='input_register_personality_email' msgId='text_register_personality_email' />
      </FormItem>
      {mobileVisible && <>
        <FormItem name="phone" label='手机号码：' rules={MOBILE_RULES}>
          <Input onBlur={() => handleBlur('phone')} onFocus={() => handleFocus('phone', '请输入11位手机号')}
            msg={formItem?.phone} id='input_register_personality_phone' msgId='text_register_personality_phone' />
        </FormItem>
        <FormItem name="checkCode" label='短信验证码：' rules={CHECK_CODE_RULES}>
          <SendMsg onBlurInput={() => handleBlur('checkCode')} phoneNumber={form.getFieldValue('phone')}
            inputId='input_register_personality_code' btnId='btn_register_personality_code'
            sourceType={SOURCE_TYPE_PER}
            disabled={!form.getFieldValue('phone') || !new RegExp(REGEX_MOBILE).test(form.getFieldValue('phone'))} msg={formItem?.checkCode}
            onError={onError} sendUserType='phone' captchaWrapClassName={'gy_send_msg_captcha'}></SendMsg>
        </FormItem>
      </>}

    </Form>
    <Captcha
      onSubmit={handleCaptchaSubmit}
      bId={BID_REGISTER_GAME}
      onClose={handleValidClose}
      visible={captchaVisible}
      wrapClassName={'gy_send_msg_captcha'}
    />
  </>
}


export default Personality