/*
 * @Author: liujunhong
 * @Date: 2022/12/24
 * @Desc: 发送短信
 */
import React from "react";
import { SendVerificationCode } from "gyyx-component-lib";
import classNames from "classnames";


const Index = (props) => {
  const {inputId, btnId, sourceType, disabled = false, msg, value = '', sendUserType, ...otherProps} = props
  return <div className='form_item_msg_wrap'>
    <SendVerificationCode
      sendInputId={inputId}
      sendBtnId={btnId}
      sourceType={sourceType}
      vcValue={value}
      disabled={disabled}
      className='form_item_msg'
      sendUserType={sendUserType}
      {...otherProps}
    ></SendVerificationCode>
    {msg && <span className={classNames('form_item_msg_text')}>{msg}</span>}
  </div>

}

export default Index