import { useState, useEffect } from 'react';
import Axios from 'axios';
import qs from "qs";

Axios.defaults.withCredentials = true;

export const axios = Axios


export const getCookie = (name) => {
    const arr = document.cookie.split('; ');
    for (var i = 0; i < arr.length; i++) {
        var arr1 = arr[i].split('=');
        if (arr1[0] === name) {
            return arr1[1];
        }
    }
    return '';
}

export const useAxios = (url, type = 'get', params = {}, headers = {}) => {
    const [data, setData] = useState({ data: null, isLoad: false });

    useEffect(() => {
        let selfAxios = null;
        const requestFn = async () => {
            setData({ data: null, isLoad: true });
            let res = null;
            let CancelToken = Axios.CancelToken;
            if (type === 'post') {
                res = await Axios({
                    method: type,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                        ...headers
                    },
                    url,
                    data: qs.stringify(params)
                })
            } else {
                res = await Axios.get(url, {
                    cancelToken: new CancelToken(function executor(c) {
                        selfAxios = c;
                    }),
                    params,
                    headers
                })
            }
            setData({ data: res.data, isLoad: false });
        }
        requestFn();
        return () => {
            if (selfAxios && !data.data) {
                selfAxios()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url])

    return data;
}

export const ajaxRequest = (url, type = 'get', params = {}, headers = {}) => {
    let resAxios = null;
    if (type === 'post') {
        resAxios = Axios({
            method: type,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                ...headers
            },
            url,
            data: qs.stringify(params)
        })
    } else {
        resAxios = Axios.get(url, { params, headers })
    }

    return new Promise((resolve,reject) => {
        resAxios.then((res) => {
            resolve(res.data);
        }).catch((error) => {
            // 服务器错误
            reject({
                success: false,
                message: "服务器错误",
                data: null
            })
        })
    })
}


export const loadScript = (url, id, callback = () => { }) => {
	const scriptNode = document.getElementById(id);
	if (scriptNode && !scriptNode.length) {
		callback();
		return false;
	}
	var script = document.createElement("script");
	script.type = "text/javascript";
	script.id = id;
	if (script.readyState) {    //    IE
		script.onreadystatechange = function () {
			if (script.readyState === "loaded" || script.readyState === "complete") {
				script.onreadystatechange = null;        // 只要loaded和complete任何一个触发，则删除事件处理器（以确保事件不会处理两次）
				callback();
			}
		}
	} else {    //其他浏览器
		script.onload = function () {
			callback();
		}
	}
	script.src = url;
	document.getElementsByTagName("head")[0].appendChild(script);
}

export const showLoginPop = () => {
    window.$('.top_bg_red').attr('data-status', 'false');
    window.alertFn(null, null, true);
}


export const verpassword2 = (value) => {
  let str1 = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let str2 = "abcdefghijklmnopqrstuvwxyz";
  let str3 = "0123456789";
  let flag = true;
  let exp = new RegExp("^[a-z]+$");
  let lower = exp.test(value);

  //如果是非小写字母
  if (isNaN(value) && !lower) {
    for (let i = 0; i < str1.length; i++) {
      if (value.substring(0, value.length) === str1.substring(i, i + value.length)) {
        flag = false;
        return flag;
      }
    }
    return flag;
  }
  //如果是小写字母
  if (isNaN(value) && lower) {
    for (let z1 = 0; z1 < str2.length; z1++) {
      if (value.substring(0, value.length) === str2.substring(z1, z1 + value.length)) {
        flag = false;
        return flag;
      }
    }
    return flag;
  }
  //如果是数字
  if (!isNaN(value)) {
    for (let j1 = 0; j1 < str3.length; j1++) {
      if (value.substring(0, value.length) === str3.substring(j1, j1 + value.length)) {
        flag = false; return flag;
      }
    }
    return flag;
  }
  return flag;
}