/*
* @Author: liujunhong
* @Date: 2022/12/20
* @Desc:
*/


import GyyxBaseLib from "gyyx-base-lib"
import { verpassword2 } from '../utils/common'

export const REGEX_ACCOUNT = '^[a-zA-Z][A-Za-z\\d]+$'
// 密码
export const REGEX_PW = '^[a-zA-Z0-9!\'()*_.~-]{6,16}$'
// 相同
export const REGEX_SAME = '^(.)1*$'
// 邮箱
export const REGEX_E_MAIL = '^([.a-zA-Z\\d_-])+@([a-zA-Z\\d_-])+((\\.[a-zA-Z\\d_-]{2,3}){1,2})$'

export const REGEX_TRUE_NAME = '^[\u4e00-\u9fa5·]{2,30}$'

// 手机号
export const REGEX_MOBILE = '^[1|2]\\d{10}$'
// 验证码
export const REGEX_CODE = '^[A-Za-z\\d]+$'
// 中文
export const REGEX_CHS = '[\u4e00-\u9fa5]'
// 个性化注册sourceType
export const SOURCE_TYPE_PER = 'PersonalReg'
// 手机号注册sourceType
export const SOURCE_TYPE_PHONE = 'MobilePhoneStageReg'
// 游戏注册
export const BID_REGISTER_GAME = 'ua8rmuhut68m'

// 游戏内补充三项
export const BID_COMPLETE_INFO = 'bi7fgkt464pl'


//个性注册来源
export const ENTRY_PER = "clientpersonality"
//盘龙注册来源
export const ENTRY_PER_PL = "tjlongpersonality"

export const ACCOUNT_RULES = [
  { rule: "require", message: '此项为必填项' },
  { rule: "between:6,16", message: '您的格式错误' },
  { rule: `regexp:${REGEX_ACCOUNT}`, message: "您的格式错误" },
]

export const PW_RULES = [
  { rule: "require", message: '此项为必填项' },
  { rule: `regexp:${REGEX_PW}`, message: '6-16位字符，(除<和>以及空格)' },
  { rule: `not_regexp:${REGEX_SAME}`, message: '请不要使用同一个字符设置密码' },
  {
    rule: (param) => {
      return verpassword2(param)
    }, message: '请不要使用连续字符设置密码'
  }
]


export const TRUE_NAME_RULES = [
  { rule: "require", message: '此项为必填项' },
  { rule: `regexp:${REGEX_TRUE_NAME}`, message: '请输入中文' }
]

export const CER_NUM_RULES = [
  { rule: "require", message: '此项为必填项' },
  {
    rule: (param) => {
      return GyyxBaseLib.checkIdCard(param) !== 2
    }, message: '您输入的身份证号码不正确'
  },
  {
    rule: (param) => {
      return GyyxBaseLib.checkIdCard(param) !== 3
    }, message: '未成年人不允许注册，请检查'
  },
]

export const E_MAIL_RULES = [
  { rule: "require", message: '此项为必填项' },
  { rule: `regexp:${REGEX_E_MAIL}`, message: '电子邮箱格式不正确' }
]

export const MOBILE_RULES = [
  { rule: "require", message: '此项为必填项' },
  { rule: "between:11,11", message: '对不起，请输入11位手机号！' },
  { rule: `regexp:${REGEX_MOBILE}`, message: '您的格式错误' }
]
export const CHECK_CODE_RULES = [
  { rule: "require", message: '此项为必填项' },
  { rule: "between:5,5", message: '验证码不正确' },
  { rule: `regexp:${REGEX_CODE}`, message: '您的格式错误' }
]

export const USE_TYPE = 'game'