/*
 * @Author: liujunhong
 * @Date: 2023/1/9
 * @Desc: 
 */

import './css/newgyyxindex.scss'
const SafetyGuide = () => {
  return <div className='safety_guide_wrap'>
    <p className='safety_guide_title'><strong>防盗安全指南 </strong></p>

    <div className='safety_guide_content'>
      <ul>
        <strong>一、如何高度保护帐号安全：</strong>
        <li>1、记录清楚您的注册资料，并定时查看您的社区是否处于封停状态，这样可以避免出现帐号密码被人恶意修改；</li>
        <li>2、尽量不选择与“朋友”共用帐号，避免日后因为帐号可能引起不愉快甚至纠纷，游戏中的朋友可能平时称兄道弟，但是难免会被您帐号上的极品装备、宠物所诱惑。要知道，物品被盗是很难全部找回的；</li>
        <li>3、绑定密码保护产品。使用密码保护产品，可以大幅提高您的帐号安全，减少被盗号的风险；</li>
        <li>4、如果帐号出现异常或者不幸被盗，请立即申请帐号临时封停，并迅速联系游戏客服。您及时的提交您的被盗情况，可以使我们很快的查到盗号者的所在；</li>
        <li>5、提醒您的朋友和您一样注意安全。本专题内有很多防盗知识，请您多加留意，并告诉您的朋友，共同保护好您的帐号。</li>
      </ul>
      <ul>
        <strong>二、在家登录如何保护帐号：</strong>
        <li>1、安装防病毒软件和防火墙，并保持日常更新和定时的病毒查杀。但是往往新种类的病毒总是先于杀毒软件出现的，所以良好的上网习惯才是保证上网安全的前提；</li>
        <li>2、保证尽快更新 Windows 操作系统安全组件，确保系统安全。我们推荐使用安全卫士360软件来保证您系统的实时更新；</li>
        <li>3、不要随意下载。建议您去正规的网站下载，在网上随意搜索的资源可能暗藏病毒。下载后也要记得先查毒。同时在页面上看到的各类诱惑性的文字链接及图片链接背后，都有可能隐藏着猎取帐号密码的木马；</li>
        <li>4、不要打开陌生的网址，即使是您QQ/Popo/MSN上很熟的好友发送给您的，也要千万小心，因为很多病毒会伪装成您的好友给您发送消息，例如QQ尾巴这类木马。如有陌生人提出视频或者传送图片、文件的邀请，点“接受”前都要三思</li>
        <li>5、不要把您的帐号和密码明文保存在文档中，那样也是很危险的；</li>
        <li>6、不要使用外挂，事实上很多外挂本身就是盗号的病毒，而且使用外挂会被官方处以严重的惩罚；</li>
        <li>7、定期更换密码，并且密码的长度要至少在8位数以上，使用数字和字母组合，用来增加您帐号的安全性；</li>
        <li> 8、虽然家庭的电脑相对安全，但是我们建议您绑定一款密码保护产品，那样可以让您更加安全，以备不测。目前我们建议您使用乾坤锁或电话密保，具体绑定方式请见<a href='http://security.gyyx.cn'>http://security.gyyx.cn</a></li>
      </ul>
      <ul>
        <strong>三、在网吧登录如何保护帐号：</strong>
        <li>1、上机前/后重新启动电脑，千万不要偷懒，现在大部分网吧都装有“还原精灵”或“硬盘还原卡”等，重启电脑可以恢复到正常状态，以防止一些驻留在内存的木马窃取您的帐号/密码；</li>
        <li>2、当您输入密码或者进行解锁操作时，一定要环顾四周看看是否有人在窥视您，避免帐号/密码被别人看到；</li>
        <li>3、大多数木马是通过记录你的键盘输入而截取你的帐号的，因此我们可以利用一些技巧来“蒙骗”木马程序，让它不会轻易算出你的帐号。错位输入法就是在你输入帐号和密码的时候，不要按顺序从左至右输入。而是先输入右边几位，然后再输入左边几位，最后再输入中间几位。总之您可以随意跳着输入，步骤越复杂越好。这样即使有木马程序，它也很难判断出你的正确帐号密码是什么；</li>
        <li>4、尽量不去不熟悉或者管理混乱的网吧，大型的网吧通常管理比较规范，也相对安全；</li>
        <li>5、不要随意登录陌生的网站，尤其是所谓的“私服”、“外挂”网站；</li>
        <li>6、不使用任何游戏外挂，很多外挂本身就包含了盗号的病毒程序；</li>
        <li>7、当您离开座位时，记住先锁好电脑或者先退出游戏以确保安全；</li>
        <li>8、使用密码保护产品，那样可以大幅提高您的帐号安全度。注意：不要使用网吧公用电话绑定电话密保；</li>
        <li>9、不要轻易在网吧使用社区密码、注册资料进行操作。</li>
      </ul>
    </div>
  </div>

}

export default SafetyGuide