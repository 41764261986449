/*
 * @Author: liujunhong
 * @Date: 2023/3/13
 * @Desc: 
 */

export const MAP_STATIC_URL = {
  BANNER: '//static.gyyx.cn/Stage/community/homeBanner.js',
  INFORMATION: '//static.gyyx.cn/Stage/community/homeInformation.js',
  GAME: '//static.gyyx.cn/Stage/community/homeGame.js'
}

export const BANNER_LIST = [{
  id: 0,
  img: '//img.gyyxcdn.cn/www/NewHome/banner.png',
  isShowDetailBtn: true,
  detailBtnText: '查看详情 +',
  hrefDetail: '',
  isShowCode: true,
  hrefCodeDown: '//img.gyyxcdn.cn/www/NewHome/downCode.jpg',
  hrefCodeHome: '//img.gyyxcdn.cn/www/NewHome/homeCode.jpg',
  textDown: '下载游戏',
  hrefDown: '',
  textHome: '进入官网',
  hrefHome: '',
},
  {
    id: 1,
    img: '//img.gyyxcdn.cn/www/NewHome/banner.png',
    isShowDetailBtn: true,
    detailBtnText: '查看详情 +',
    hrefDetail: '',
    isShowCode: true,
    hrefCodeDown: '//img.gyyxcdn.cn/www/NewHome/downCode.jpg',
    hrefCodeHome: '//img.gyyxcdn.cn/www/NewHome/homeCode.jpg',
    textDown: '下载游戏',
    hrefDown: '',
    textHome: '进入官网',
    hrefHome: '',
  }]

export const INFORMATION_LIST = [{
  id: 0,
  img: '//img.gyyxcdn.cn/www/NewHome/information_img1.png',
  title: '9月公测版百宝翻牌',
  href: '',
  content: '每次置换需要花费100金元宝（本页面图片均为内测区截图）；若只置换第三张，则第一次100金元宝，之后会递增。若三张凑齐了同一种图片。'
}, {
  id: 1,
  img: '//img.gyyxcdn.cn/www/NewHome/information_img2.png',
  title: '9月公测版百宝翻牌',
  content: '每次置换需要花费100金元宝（本页面图片均为内测区截图）；若只置换第三张，则第一次100金元宝，之后会递增。'
}, {
  id: 2,
  img: '//img.gyyxcdn.cn/www/NewHome/information_img3.png',
  title: '9月公测版百宝翻牌',
  content: '每次置换需要花费100金元宝（本页面图片均为内测区截图）；若只置换第三张，则第一次100金元宝，之后会递增。则可获得此张图片中的奖励。'
},
  {
    id: 3,
    img: '//img.gyyxcdn.cn/www/NewHome/information_img3.png',
    title: '9月公测版百宝翻牌',
    content: '每次置换需要花费100金元宝（本页面图片均为内测区截图）；若只置换第三张，则第一次100金元宝，之后会递增。则可获得此张图片中的奖励。'
  }, {
    id: 4,
    img: '//img.gyyxcdn.cn/www/NewHome/information_img2.png',
    title: '9月公测版百宝翻牌',
    content: '每次置换需要花费100金元宝（本页面图片均为内测区截图）；若只置换第三张，则第一次100金元宝，之后会递增。'
  },
]

export const GAME_LIST = [{
  gameLogo: '//img.gyyxcdn.cn/www/NewHome/game_logo.png',
  gameSign: '//img.gyyxcdn.cn/www/NewHome/game_img1.png',
  gameBg: '//img.gyyxcdn.cn/www/NewHome/game_bg.png',
  gameName: '问道',
  gameIntro: '风举云摇入九天，剑挂北辰紫微垣。鼓帆舟行银汉上，白月满空星斗寒。1.73星界启航版本扬帆上线。此外针对游戏内的部分系统及玩法也进行了调整及优化。',
  hrefText: '进入官网',
  href: ''
}, {
  gameLogo: '',
  gameSign: '//img.gyyxcdn.cn/www/NewHome/game_img2.png',
  gameBg: '',
  gameName: '神道三国',
  gameIntro: '风举云摇入九天，剑挂北辰紫微垣。鼓帆舟行银汉上，白月满空星斗寒。1.73星界启航版本扬帆上线。此外针对游戏内的部分系统及玩法也进行了调整及优化。',
  hrefText: '进入官网',
  href: ''
}, {
  gameLogo: '',
  gameSign: '//img.gyyxcdn.cn/www/NewHome/game_img3.png',
  gameBg: '',
  gameName: '绝地反击',
  gameIntro: '风举云摇入九天，剑挂北辰紫微垣。鼓帆舟行银汉上，白月满空星斗寒。1.73星界启航版本扬帆上线。此外针对游戏内的部分系统及玩法也进行了调整及优化。',
  hrefText: '进入官网',
  href: ''
}, {
  gameLogo: '',
  gameSign: '//img.gyyxcdn.cn/www/NewHome/game_img4.png',
  gameBg: '',
  gameName: '风帆纪元',
  gameIntro: '风举云摇入九天，剑挂北辰紫微垣。鼓帆舟行银汉上，白月满空星斗寒。1.73星界启航版本扬帆上线。此外针对游戏内的部分系统及玩法也进行了调整及优化。',
  hrefText: '进入官网',
  href: ''
}]