export const CHECK_MAP = {
    2: '请输入正确的身份证号码',
    3: '未成年人不允许实名'
}
/**
 * 检查证件号
 * @param res
 * @returns {*|string}
 */
export const handleCheck = (res) => {
    let val = CHECK_MAP[res]
    return val ? val : ''
}
// 验证码bid
export const BID_REAL_NAME = 'rs465yovieup';

export const BID_REAL_NAME_GAME = 'hnedpr6y19ig'