/*
 * @Author: liujunhong
 * @Date: 2022/12/20
 * @Desc: 
 */
import React, { useEffect, useState } from 'react'
import './style.scss'
import classNames from 'classnames'
import { registerSmsOpen } from "../../servers/register";
import { AGREEMENT_URL, SAFETY_GUIDE_URL } from "../../consts";
import Personality from "./personality";
import Phone from './phone'


const Index = () => {
  const [curTab, setCurTab] = useState('personality')
  const [mobileVisible, setMobileVisible] = useState(false)
  const [isAgree, setIsAgree] = useState(true)
  const [cnt, setCnt] = useState(0)
  const [registerSuccess, setRegisterSuccess] = useState(false)

  const handleChange = (cur) => {
    setCurTab(cur)
    setCnt(0)
    localStorage.removeItem('PersonalReg_time')
  }

  const onSuccess = () => {
    setRegisterSuccess(true)
  }


  const comMap = {
    'personality': <Personality mobileVisible={mobileVisible} cnt={cnt} isAgree={isAgree} onSuccess={onSuccess}/>,
    'phone': <Phone cnt={cnt} isAgree={isAgree} onSuccess={onSuccess}/>
  }


  useEffect(() => {
    if (curTab === 'personality') {
      registerSmsOpen().then(res => {
        if (!res) {
          return
        }
        setMobileVisible(res.data)
      })
    }

  }, [curTab])

  const handleSubmit = () => {
    let cntClick = cnt
    cntClick++
    setCnt(cntClick)
  }


  return <>
    <div
      className={classNames('front_game_wrap', 'client_register_wrap', {'client_register_wrap_success': registerSuccess})}>

      <div className='client_register_type'>
          <span
            className={classNames('client_register_item client_register_personality', {'cur': curTab === 'personality'})}
            onClick={() => handleChange('personality')}></span>
        <span className={classNames('client_register_item client_register_phone', {'cur': curTab === 'phone'})}
              onClick={() => handleChange('phone')}></span>
      </div>
      <div className='client_register_form'>
        {comMap[curTab]}
      </div>
      <div className='client_register_form_submit'>
        <span className='client_register_form_submit_btn' id='btn_client_register' onClick={handleSubmit}></span>
        <div className={classNames('client_register_agreement')}>
          <span
            className={classNames('client_register_agreement_checkbox', {agree: isAgree})}
            onClick={() => setIsAgree(!isAgree)}></span>
          <a href={AGREEMENT_URL} target="_blank" className='client_register_agreement_a'>《光宇通行证用户协议》</a>
        </div>
        <a href={SAFETY_GUIDE_URL} className='client_register_guide' target="_blank">防盗安全指南</a>
      </div>
    </div>

    <div className={classNames('client_register_success', {'register_success': registerSuccess})}>
    </div>
  </>

}
export default Index
