/*
 * @Author: liujunhong
 * @Date: 2022/12/26
 * @Desc: 
 */

import {REGEX_CHS, REGEX_E_MAIL, REGEX_TRUE_NAME} from "../../consts/register";
import GyyxBaseLib from "gyyx-base-lib";

export const REGEX_ACCOUNT = '^[a-zA-Z][A-Za-z\\d]+$'
// 密码
export const REGEX_PW = '^[^<>\u4e00-\u9fa5]{4,16}$';



export const ACCOUNT_RULES = [
  {rule: "between:6,16", message: '社区账号必须在6-16个字符之间'},
  {rule: `!regexp:${REGEX_CHS}`, message: '社区账号禁止输入中文'}
]


export const PW_RULES = [
  {rule: "between:4,16", message: '社区密码必须在4-16个字符之间'},
  {rule: `regexp:${REGEX_PW}`, message: '社区密码必须在4-16个字符之间'}
]



export const E_MAIL_RULES = [
  {rule: `regexp:${REGEX_E_MAIL}`, message: '邮箱格式不正确，请重新输入'}
]

export const TRUE_NAME_RULES = [
  {rule: "between:2,30", message: '注册姓名必须在2-30个汉字之间'},
  {rule: `regexp:${REGEX_TRUE_NAME}`, message: '注册姓名只能填写中文'}
]

export const CER_NUM_RULES = [
  {
    rule: (param) => {
      return GyyxBaseLib.checkIdCard(param) !== 2
    }, message: '您输入的身份证号码不正确'
  },
  {
    rule: (param) => {
      return GyyxBaseLib.checkIdCard(param) !== 3
    }, message: '未成年人不允许注册，请检查'
  },
]