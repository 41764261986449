/*
 * @Author: liujunhong
 * @Date: 2022/12/24
 * @Desc: 手机注册
 */
import React, { useEffect, useState } from "react";
import Form, { Field as FormItem } from "../../components/CustomFormControl/Form";
import {
  CER_NUM_RULES,
  CHECK_CODE_RULES,
  E_MAIL_RULES,
  ENTRY_PER,
  MOBILE_RULES,
  PW_RULES,
  REGEX_MOBILE,
  SOURCE_TYPE_PHONE,
  TRUE_NAME_RULES,
  USE_TYPE
} from "../../consts/register";
import { RegisterPhone } from "account-net-sdk";
import Input from "../../components/CustomFormControl/Input";
import SendMsg from '../../components/CustomFormControl/SendMsg'


const Phone = (props) => {
  const {cnt, isAgree, onSuccess} = props
  const [form] = Form.useForm();
  const [formItem, setFormItem] = useState({})
  const [errorTips, setErrorTips] = useState('')

  const handleBlur = (key) => {
    let validMap = form.validate(key)
    if (!Object.keys(validMap).length) {
      delete formItem[key]
      setFormItem({...formItem})
      return
    }
    setFormItem({...formItem, ...validMap})
  }

  const handleFocus = (key, msg) => {
    setFormItem({...formItem, [key]: msg})
  }

  const onError = (res) => {
    setFormItem({...formItem, 'checkCode': res.message})
  }

  const handleSubmit = () => {
    const error = form.validate()
    console.log(error)
    if (Object.keys(error).length) {
      setFormItem({...error})
      return
    }
    if (!isAgree) {
      setErrorTips('请勾选并阅读协议')
      return
    }
    requestSubmit()
  }

  const requestSubmit = () => {
    const {phone, checkCode, password, trueName, email, idCard} = form.getFieldsValue()

    let params = {
      userName: phone.trim(),
      checkCode,
      password,
      trueName,
      email,
      idCard,
      regEntry: ENTRY_PER,
    }

    RegisterPhone(params, USE_TYPE).then(res => {
      console.log('res', res)
      if (!res.data.success) {
        setErrorTips(res.data.message)
        return
      }
      onSuccess()
    })
  }


  useEffect(() => {
    if (!cnt) {
      return
    }

    handleSubmit()
  }, [cnt])


  return <>
    {<p className='client_register_tips'>{errorTips}</p>}
    <Form form={form}>
      <FormItem name="phone" label='手机号码：' rules={MOBILE_RULES}>
        <Input onBlur={() => handleBlur('phone')} onFocus={() => handleFocus('phone', '请输入11位手机号')}
               msg={formItem?.phone} id='input_register_phone' msgId='text_register_phone'/>
      </FormItem>
      <FormItem name="checkCode" label='短信验证码：' rules={CHECK_CODE_RULES}>
        <SendMsg onBlurInput={() => handleBlur('checkCode')} phoneNumber={form.getFieldValue('phone')}
                 inputId='input_register_phone_code' btnId='btn_register_phone_code'
                 sourceType={SOURCE_TYPE_PHONE}
                 disabled={!form.getFieldValue('phone') || !new RegExp(REGEX_MOBILE).test(form.getFieldValue('phone'))}
                 msg={formItem?.checkCode}
                 onError={onError} sendUserType='phone' captchaWrapClassName={'gy_send_msg_captcha'}></SendMsg>
      </FormItem>
      <FormItem name="password" label='设置密码：' rules={PW_RULES}>
        <Input type='password' onBlur={() => handleBlur('password')}
               onFocus={() => handleFocus('password', '6-16位字符，(除<和>以及空格)')} msg={formItem?.password}
               id='input_register_phone_password' msgId='text_register_phone_password'/>
      </FormItem>
      <FormItem name="trueName" label='真实姓名：' rules={TRUE_NAME_RULES}>
        <Input onBlur={() => handleBlur('trueName')}
               onFocus={() => handleFocus('trueName', '真实姓名由汉字组成')} msg={formItem?.trueName}
               id='input_register_phone_trueName' msgId='text_register_phone_trueName'/>
      </FormItem>
      <FormItem name="idCard" label='身份证：' rules={CER_NUM_RULES}>
        <Input onBlur={() => handleBlur('idCard')}
               onFocus={() => handleFocus('idCard', '证件号不能修改，请谨慎填写')} msg={formItem?.idCard}
               id='input_register_phone_idCard' msgId='text_register_phone_idCard'/>
      </FormItem>
      <FormItem name="email" label='电子邮箱：' rules={E_MAIL_RULES}>
        <Input onBlur={() => handleBlur('email')}
               onFocus={() => handleFocus('email', `官方推荐使用'QQ邮箱'`)} msg={formItem?.email}
               id='input_register_phone_email' msgId='text_register_phone_email'/>
      </FormItem>
    </Form></>
}

export default Phone

