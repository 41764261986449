import React from 'react';
import { newsListUrl } from '../../../api/newsService';
import { useAxios } from '../../../utils/common';
import moment from 'moment';

const typeTranslate = {
    2:{
        title:"光宇动态",
        wrapClassName:"sq_tggg",
        listClassName:"sq_tggg_list newlist",
        href:"//www.gyyx.cn/news/list/Trends/1/"
    },
    3:{
        title:"推广公告",
        wrapClassName:"sq_tggg sq_margin_top",
        listClassName:"sq_tggg_list sprlist",
        href:"//www.gyyx.cn/News/List/extend/1"
    }
    
}

export default ({type})=>{
    let pageSize = 20;
    let pageIndex = 1;
    const { data } = useAxios(`${newsListUrl}?pageIndex=${pageIndex}&typeId=${type}`, 'get', {
        pageSize,
    });
    return (
        <div className={typeTranslate[type].wrapClassName}>
            <h2 className="sq_tgzx_title clear">
                <a className="sq_more" href={typeTranslate[type].href}>查看更多</a>
                {typeTranslate[type].title}
            </h2>
            <ul className={typeTranslate[type].listClassName}>
                      {
                       
                            data?.data?.list.slice(0,10).map((item, index) =>{
                                return (
                                    <li key={item.Code}>
                                      {index === 0? "" :<span>[{moment(item.ShowTime).format('DD')}]</span>} 
                                        <a href={"/News/Detail/"+ item.Code} target="_blank">{item.Title.length> 13?item.Title.substring(0, 13)+"...":item.Title}</a>
                                    </li>
                                )

                            })
                     }
            </ul>
        </div>
    )
}