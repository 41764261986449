/*
 * @Author: liujunhong
 * @Date: 2023/6/27
 * @Desc: 游戏内扫码
 */


import QRCode from 'qrcode.react';
import { useEffect, useState } from "react";
import AliLogo from '../../images/ali.png'

const Index = () => {
  const [qrCodeUrl, setQrCodeUrl] = useState('')

  useEffect(() => {
    const href = window.location.href
    const url = new URL(href);
    const params = new URLSearchParams(url.search);
    const qrcode = params.get('qrcode');
    console.log(qrcode)
    setQrCodeUrl(decodeURIComponent(qrcode))
  }, [])
  return <div style={{
    overflow: 'hidden',
  }}>
    <div style={{
      margin: '17px 0 0 17px',
    }}>
      <QRCode
        id="pay_qr_code"
        value={qrCodeUrl}
        fgColor="#000"
        level="H"
        style={{
          margin: '0 auto',
          width: '100%',
          height: 'auto',
          display: 'flex'
        }}
        imageSettings={{
          src: AliLogo,
          height: 36,
          width: 36,
          excavate: true,
        }}

      />
    </div>
  </div>
}

export default Index
