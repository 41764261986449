/*
 * @Author: liujunhong
 * @Date: 2022/11/1
 * @Desc:
 */


import {Route, Switch} from "react-router-dom";
import React, {Suspense} from "react";
import routes from '../routes.js';

const Routes = () => {
    return <Suspense>
        <Switch>
            {
                routes.map(function (route, index) {
                    return (<Route
                        key={index}
                        path={route.path}
                        name={route.name}
                        component={route.component}
                    />)

                })
            }
        </Switch>
    </Suspense>
}

export default Routes

