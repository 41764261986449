import React from 'react';
import DetailsPassage from './DetailsPassage.js';

export default (props) => {
  const code = props.code;
  return (
    <div className="sq_main_right">
      <DetailsPassage code={code}/>

    </div>

  )

}