import React from 'react';
import TopNav from './components/TopNav.js';
import LeftDetailsNav from './components/LeftDetailsNav.js';
import RightDetailsNav from './components/RightDetailsNav.js';
import './components/css/style.scss';

export default (props) => {
    return (
        <div className="news_page">
            <TopNav/>
            <div className="mainBox">
                <div className="main_cha">
                    <LeftDetailsNav type={props}/>
                    <RightDetailsNav code={props.match.params.id}/>
                </div>
            </div>
        </div>
    )
}