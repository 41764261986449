/*
 * @Author: liujunhong
 * @Date: 2022/12/30
 * @Desc: 
 */

import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Layout from '../components/Layout'
import CompleteInfo from "../page/completeInfo"
import ClientRegister from "../page/clientRegister"
import DragonRegister from "../page/dragonRegister"
import GameRealName from "../page/realName/game"
import { Foot } from "gyyx-component-lib"
import Agreement from '../page/Index/Agreement'
import SafetyGuidePage from "../page/Index/safetyGuide"
import GamePayScan from "../page/gamePayScan"

const AgreementPage = () => {
  return <>
    <Agreement />
    <Foot footId='footer-front-www' />
  </>
}

export default () => (
  <Switch>
    <Route path='/game/realname/frame' component={GameRealName} key='realName' />
    <Route path='/game/completeInfo' component={CompleteInfo} key='completeInfo' />
    <Route path='/game/clientRegister' component={ClientRegister} key='clientRegister' />
    <Route path='/game/dragonRegister' component={DragonRegister} key='dragonRegister' />
    <Route path='/Resource/Agreement' component={AgreementPage} key='agreement' />
    <Route path='/Resource/Agreement.html' component={AgreementPage} key='agreement' />
    <Route path='/Resource/Agreementwap.html' component={AgreementPage} key='agreement' />
    <Route path='/Resource/SafetyGuide' component={SafetyGuidePage} key='SafetyGuide' />
    <Route path='/Resource/SafetyGuide.html' component={SafetyGuidePage} key='SafetyGuide' />
    <Route path='/AlipayGYQrcodeGame/QrPage' component={GamePayScan}></Route>
    <Route path='/' component={Layout} key='app' />
  </Switch>
)
