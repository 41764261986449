/*
 * @Author: liujunhong
 * @Date: 2022/12/20
 * @Desc: 
 */

export const REQUEST_URL = '//accountv5.gyyx.cn'

export const AGREEMENT_URL = '//www.gyyx.cn/Resource/Agreement'

export const AGREEMENT_URL_PL = 'http://qibao.gyyx.cn/qibao/panlongProtocol.html'

export const SAFETY_GUIDE_URL = '//www.gyyx.cn/Resource/SafetyGuide.html'

export const DOWN_URL  = '//down.gyyx.cn'


export const MAP_URL_PAGE = {
  REGISTER: '//account.gyyx.cn/Member/Register',
  PAY: '//pay.gyyx.cn/',
  QI_BAO: '//qibao.gyyx.cn/',
  ACCOUNT: '//account.gyyx.cn/',
  FA_Q: '//kf.gyyx.cn/',
  CHANGE: '//change.gyyx.cn/',
  DAO: 'http://dao.gyyx.cn/',
  UNDERAGE: '//kf.gyyx.cn/jianhu/jz_2010.html'
}