import React from "react";
import classNames from "classnames";
import './style.scss'

const Index = (props) => {
  return <input {...props} />;
};

const CustomInput = (props) => {
  const {value = '', msg = '', label, msgId, ...otherProps} = props;


  return <div className='form_item_input_wrap'>
    <Index style={{outline: "none"}} value={value} {...otherProps}
           className='form_item_input'/>
    {msg && <span className={classNames('form_item_msg')} id={msgId}>{msg}</span>}
  </div>
}

export default CustomInput;
