import {useRef} from 'react'
import {rulesValid} from "../../../utils/validation";

export default function useForm(form) {
  const formRef = useRef()

  if (!formRef.current) {
    if (form) {
      formRef.current = form
    } else {
      const formStore = new FormStore()
      formRef.current = formStore.getForm()
    }
  }
  return [formRef.current];
}


class FormStore {
  constructor() {
    this.store = {}
    this.fields = []
    this.cbs = {}
  }

  setCallbacks = (callbacks) => {
    this.cbs = {
      ...this.cbs,
      ...callbacks
    }
  }
  // 订阅 注册
  registerField = (field) => {
    this.fields.push(field)

    // 取消订阅 取消注册
    return () => {
      this.fields = this.fields.filter(n => n !== field)
      delete this.store[field.props.name]
    }
  }

  setFieldsValue = (newStore) => {
    this.store = {
      ...this.store,
      ...newStore,
    };

    // 更新组件
    this.fields.forEach(n => {
      Object.keys(newStore).forEach(key => {
        n.props.name === key && n.onStoreChange()
      })
    })
  }

  getFieldsValue = () => {
    return {...this.store}
  }

  getFieldValue = (key) => {
    return this.store[key]
  }

  validate = (key) => {
    let err = {};
    if (key) {
      const value = this.getFieldValue(key);
      let arr = this.fields.filter(entity => {
        return entity.props.name === key;
      })
      const {valid, msg} = rulesValid(value, arr[0].props.rules)
      if (!valid) {
        err[key] = msg
      }
      return err
    }

    this.fields.forEach((entity) => {
      const {name, rules} = entity.props;
      console.log('value', this.getFieldValue(name))
      const value = this.getFieldValue(name) || '';
      const {valid, msg} = rulesValid(value, rules)
      if (!valid) {
        err[name] = msg
      }
    });
    return err;
  }

  submit = () => {
    const err = this.validate()
    console.log()
    const {onFinish, onFinishFailed} = this.cbs
    if (Object.keys(err).length) {
      onFinishFailed(err, this.getFieldsValue())
    } else {
      onFinish(this.getFieldsValue())
    }
  }

  getForm = () => {
    return {
      getFieldValue: this.getFieldValue,
      getFieldsValue: this.getFieldsValue,
      setFieldsValue: this.setFieldsValue,
      registerField: this.registerField,
      validate: this.validate,
      submit: this.submit,
      setCallbacks: this.setCallbacks,
    }
  }
}