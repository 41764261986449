import React from 'react';
import Page from './Page.js';

export default (props) => {
  return (
    <div className="sq_main_right">
      <div className="cont_now">当前位置：<span>
                <a
                  href={props.typeId === 2 ? "//www.gyyx.cn/news/list/Trends/1/" : "//www.gyyx.cn/News/List/extend/1"}>新闻中心</a></span> &gt;
        <span>{props.typeId === 2 ? '光宇动态' : '推广公告'}</span></div>
      <Page typeId={props.typeId}/>
    </div>

  )
}